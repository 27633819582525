import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageStartTitle: {
        id: "page.login.title",
    },
    pageStartLoginInfo: {
        id: "page.login.info",
    },
    pageStartLoginButton: {
        id: "page.login.button",
    },
});
