import { httpFaker } from "@opr-finance/utils";
import { FetchTransactionsRequest, T_VpTransactionsResponse } from "../types/transactions";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

function addParameter(url: string, key: string, value: string | number) {
    const separator = url.indexOf("?") !== -1 ? "&" : "?";
    return `${url}${separator}${key}=${value}`;
}

export async function fetchTransactions(
    data: FetchTransactionsRequest
): Promise<T_VpTransactionsResponse> {
    let url = `${data.gwUrl}/api/revolving/v2/accounts/${data.accountId}/transactions`;
    const method = "GET";

    logger.log(
        data,
        data.currentPage,
        data.currentPage !== null,
        data.currentPage !== undefined,
        data.currentPage !== null && data.currentPage !== undefined
    );

    url = data.startDate ? addParameter(url, "startDate", data.startDate) : url;
    url = data.endDate ? addParameter(url, "endDate", data.endDate) : url;
    url =
        data.shownInStatement !== null && data.shownInStatement !== undefined
            ? addParameter(url, "shownInStatement", String(data.shownInStatement))
            : url;
    url = data.excludeTransactionTypes
        ? addParameter(url, "excludeTransactionTypes", data.excludeTransactionTypes)
        : url;

    url = data.countPerPage ? addParameter(url, "countPerPage", data.countPerPage) : url;
    url = data.currentPage ? addParameter(url, "currentPage", data.currentPage) : url;

    if (data.mockApiCalls && (!data.currentPage || data.currentPage === 1)) {
        return httpFaker<FetchTransactionsRequest, T_VpTransactionsResponse>(url, method, data, {
            _links: {
                self: {
                    href: "/api/revolving/v2/accounts/6100630/transactions",
                },
                first: {
                    href: "/api/revolving/v2/accounts/6100630/transactions",
                },
                next: {
                    href: "/api/revolving/v2/accounts/6100630/transactions?currentPage=2",
                },
                last: {
                    href: "/api/revolving/v2/accounts/6100630/transactions?currentPage=4",
                },
            },
            currentPage: 1,
            total: 20,
            _embedded: {
                transactions: [
                    {
                        transactionDate: "2020-09-09 15:20:06",
                        transactionType: "StatementRoundingTransaction",
                        transactionMsg: undefined,
                        amount: -0.46,
                    },
                    {
                        transactionDate: "2020-09-09 15:20:06",
                        transactionType: "StatementInterestTransaction",
                        transactionMsg: undefined,
                        amount: 1.46,
                    },
                    {
                        transactionDate: "2020-09-09 15:20:06",
                        transactionType: "StatementAmortizationTransaction",
                        transactionMsg: undefined,
                        amount: 200.0,
                    },
                    {
                        transactionDate: "2020-09-09 15:20:06",
                        transactionType: "StatementFeeTransaction",
                        transactionMsg: undefined,
                        amount: 39.0,
                    },
                    {
                        transactionDate: "2020-09-09 15:20:06",
                        transactionType: "StatementReminderFeeTransaction",
                        transactionMsg: undefined,
                        amount: 60.0,
                    },
                    {
                        transactionDate: "2020-09-09 15:20:06",
                        transactionType: "StatementReminderFeeTransaction",
                        transactionMsg: undefined,
                        amount: 60.0,
                    },
                    {
                        transactionDate: "2020-08-15 03:00:02",
                        transactionType: "StatementRoundingTransaction",
                        transactionMsg: undefined,
                        amount: -0.01,
                    },
                    {
                        transactionDate: "2020-08-15 03:00:02",
                        transactionType: "StatementInterestTransaction",
                        transactionMsg: undefined,
                        amount: 2.01,
                    },
                    {
                        transactionDate: "2020-08-15 03:00:02",
                        transactionType: "StatementDirectDebitFeeTransaction",
                        transactionMsg: undefined,
                        amount: 39.0,
                    },
                    {
                        transactionDate: "2020-08-15 03:00:02",
                        transactionType: "StatementAmortizationTransaction",
                        transactionMsg: undefined,
                        amount: 200.0,
                    },
                ],
            },
        });
    }

    if (data.mockApiCalls && data.currentPage === 2) {
        return httpFaker<FetchTransactionsRequest, T_VpTransactionsResponse>(url, method, data, {
            _links: {
                self: {
                    href: "/api/revolving/v2/accounts/6100630/transactions?currentPage=2",
                },
                first: {
                    href: "/api/revolving/v2/accounts/6100630/transactions",
                },
                prev: {
                    href: "/api/revolving/v2/accounts/6100630/transactions",
                },
                next: {
                    href: "/api/revolving/v2/accounts/6100630/transactions?currentPage=3",
                },
                last: {
                    href: "/api/revolving/v2/accounts/6100630/transactions?currentPage=4",
                },
            },
            currentPage: 2,
            total: 20,
            _embedded: {
                transactions: [
                    {
                        transactionDate: "2020-07-15 03:00:01",
                        transactionType: "StatementRoundingTransaction",
                        transactionMsg: undefined,
                        amount: 0.13,
                    },
                    {
                        transactionDate: "2020-07-15 03:00:01",
                        transactionType: "StatementInterestTransaction",
                        transactionMsg: undefined,
                        amount: 1.87,
                    },
                    {
                        transactionDate: "2020-07-15 03:00:01",
                        transactionType: "StatementAmortizationTransaction",
                        transactionMsg: undefined,
                        amount: 200.0,
                    },
                    {
                        transactionDate: "2020-07-15 03:00:01",
                        transactionType: "StatementFeeTransaction",
                        transactionMsg: undefined,
                        amount: 39.0,
                    },
                    {
                        transactionDate: "2020-06-29 15:25:49",
                        transactionType: "ReservedTrancheTransaction",
                        transactionMsg: undefined,
                        amount: -1000.0,
                    },
                    {
                        transactionDate: "2020-06-29 15:25:39",
                        transactionType: "ReservedTrancheTransaction",
                        transactionMsg: undefined,
                        amount: -1000.0,
                    },
                    {
                        transactionDate: "2020-06-29 15:25:28",
                        transactionType: "ReservedTrancheTransaction",
                        transactionMsg: undefined,
                        amount: -1000.0,
                    },
                    {
                        transactionDate: "2020-06-29 15:25:20",
                        transactionType: "ReservedTrancheTransaction",
                        transactionMsg: undefined,
                        amount: -1000.0,
                    },
                    {
                        transactionDate: "2020-06-22 07:51:02",
                        transactionType: "ReservedTrancheTransaction",
                        transactionMsg: undefined,
                        amount: 1000.0,
                    },
                    {
                        transactionDate: "2020-06-18 11:15:16",
                        transactionType: "ReservedTrancheTransaction",
                        transactionMsg: undefined,
                        amount: -1000.0,
                    },
                ],
            },
        });
    }

    const result: Response = await fetch(url, {
        method,
        headers: { "content-type": "application/json", "x-auth-token": data.token as string },
    });
    return result.json();
}
