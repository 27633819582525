import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { appActions } from "../actions/actions";

export function LoginPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(appActions.loginPageTrigger());
    }, [dispatch]);
    return <div>Completing login...</div>;
}
