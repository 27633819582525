import * as VP from "@opr-finance/api-definitions";
import { FeatureLoginState } from "@opr-finance/feature-lfp-login";
import { DefaultInitializerType } from "@opr-finance/utils";

export enum BalanceActionConstants {
    BALANCE_INITIALIZER = "BALANCE/INITIALIZER",
    FETCH_ACCOUNT_TRIGGER = "BALANCE/FETCH_ACCOUNT_TRIGGER",
    GET_ACCOUNT_TRIGGER = "BALANCE/GET_ACCOUNT_TRIGGER",
    GET_ACCOUNT_SUCCESS = "BALANCE/GET_ACCOUNT_SUCCESS",
    FETCH_ACCOUNT_SUCCESS = "BALANCE/FETCH_ACCOUNT_SUCCESS",
    FETCH_ACCOUNT_ERROR = "BALANCE/FETCH_ACCOUNT_ERROR",
    FETCH_DISBURSEMENT_ACCOUNT_TRIGGER = "BALANCE/FETCH_DISBURSEMENT_ACCOUNT_TRIGGER",
    FETCH_DISBURSEMENT_ACCOUNT_SUCCESS = "BALANCE/FETCH_DISBURSEMENT_ACCOUNT_SUCCESS",
}

export type T_VpRevolvingAccount = VP.components["schemas"]["RevolvingAccountV2"];
export type T_VpDisbursementAccount = VP.components["schemas"]["DisbursementAccountV1"];

export type BalanceContentProps = {
    percentage: number;
};

export type BalanceTranslationProps = {
    translation: {
        minText: string;
        maxText: string;
    };
};

export type BalanceProps = BalanceContentProps & BalanceTranslationProps;

export type BalanceInitializerType = DefaultInitializerType & {
    noLoanUrl: string;
    accountId: string | undefined;
    account?: T_VpRevolvingAccount | null;
};

export type BalanceReducerState = {
    balance: T_VpRevolvingAccount | null;
    disbursementAccount: T_VpDisbursementAccount | null;
    config: BalanceInitializerType;
};

export type FeatureBalanceState = {
    balance: BalanceReducerState;
} & FeatureLoginState;
