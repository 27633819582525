import { takeEvery, put } from "redux-saga/effects";
import { AppActionConstants } from "../actions/actions";
import { errorActions } from "@opr-finance/feature-error";

export function* watchStartPageTrigger() {
    yield takeEvery(AppActionConstants.START_PAGE_TRIGGER, handleStartPageTrigger);
}

export function* handleStartPageTrigger() {
    try {
        /*
        yield put(
            translationActions.fetchTranslationTrigger({
                clientParams: {
                    space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
                    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
                },
                contentParams: {
                    select: "fields",
                    content_type: "translationV2",
                    "fields.application[in]": "Everyday Plus Online Sweden",
                    "fields.language[in]": "sv",
                    limit: 500,
                },
            })
        );
        yield take(translationActions.fetchTranslationSuccess);
*/
    } catch (e) {
        console.log("fetch start page trigger failed");
        yield put(errorActions.errorTrigger({ message: "start page load failed", url: "/error" }));
    }
}
