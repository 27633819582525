import styled from "styled-components";
import { FunctionComponent } from "react";
import { Link, LinkProps } from "react-router-dom";
import {
    border,
    color,
    layout,
    typography,
    BorderProps,
    ColorProps,
    LayoutProps,
    TypographyProps,
} from "styled-system";
import { AppThemeProps } from "@opr-finance/themes";

export type IsActiveProp = {
    active: boolean;
};

export type NavigationLinkProps = BorderProps &
    ColorProps &
    LayoutProps &
    TypographyProps &
    LinkProps &
    IsActiveProp;
export type NavigationLinkPropsWithTheme = NavigationLinkProps & AppThemeProps;

export const StyledNavigationLink: FunctionComponent<NavigationLinkProps> = styled(Link)<
    NavigationLinkProps
>`
    color: ${(props: NavigationLinkPropsWithTheme) => props.theme.colors.primary};
    font-family: ${(props: NavigationLinkPropsWithTheme) => props.theme.fonts.basic};
    font-size: ${(props: NavigationLinkPropsWithTheme) => props.theme.fontSizes[2]};
    text-decoration: none;
    ${border} 
    ${color} 
    ${layout} 
    ${typography}
    &:hover {
        > span {
            border-bottom: 1px solid #2bace2;
            font-weight: bold;
        }
    }
    ${(props) =>
        props.active &&
        `> span {
        border-bottom: 1px solid #2bace2;
        font-weight: bold;
    }`};
`;
