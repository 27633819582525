import { takeEvery, put, call, select } from "redux-saga/effects";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { balanceActions } from "../actions/balance";
import * as balanceApi from "../api/balance";
import {
    BalanceActionConstants,
    FeatureBalanceState,
    BalanceInitializerType,
    T_VpDisbursementAccount,
    T_VpRevolvingAccount,
} from "../types/balance";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchFetchAccountTrigger() {
    yield takeEvery(BalanceActionConstants.FETCH_ACCOUNT_TRIGGER, handleFetchAccountTrigger);
    yield takeEvery(BalanceActionConstants.GET_ACCOUNT_TRIGGER, handleGetAccountTrigger);
    yield takeEvery(
        BalanceActionConstants.FETCH_DISBURSEMENT_ACCOUNT_TRIGGER,
        handleFetchDisbursementAccount
    );
}

export function* handleGetAccountTrigger(
    action: ReturnType<typeof balanceActions.fetchAccountSuccess>
) {
    try {
        yield put(balanceActions.fetchAccountSuccess(action.payload));
        yield put(balanceActions.fetchDisbursementAccountTrigger(action.payload));
    } catch (e) {
        logger.log("Error getting Account");
    }
}

export function* handleFetchDisbursementAccount(
    action: ReturnType<typeof balanceActions.fetchAccountSuccess>
) {
    try {
        const { token, gwUrl, mockApiCalls } = (yield select((state: FeatureBalanceState) => {
            return state.balance.config;
        })) as BalanceInitializerType;

        if (!token) {
            logger.log("got no token, back to front page");
            return (window.location.href = "/");
        }

        const disbursementAccount: T_VpDisbursementAccount = yield call(
            balanceApi.fetchDisbursementAccount,
            {
                accountId: action.payload.id,
                token,
                mockApiCalls,
                gwUrl,
            }
        );

        yield put(balanceActions.fetchDisbursementAccountSuccess(disbursementAccount));
    } catch (e) {}
}

export function* handleFetchAccountTrigger() {
    try {
        const { token, gwUrl, noLoanUrl, mockApiCalls, accountId } = (yield select(
            (state: FeatureBalanceState) => {
                return state.balance.config;
            }
        )) as BalanceInitializerType;

        if (!token) {
            logger.log("got no token, back to front page");
            return (window.location.href = "/");
        }

        logger.log("got token, fetch account");

        const account: T_VpRevolvingAccount = yield call(balanceApi.fetchAccount, {
            accountId,
            token,
            mockApiCalls,
            gwUrl,
        });

        logger.log("got accounts", account);

        if (account.state === "CANCELLED" || account.state === "CLOSED") {
            window.location.href = noLoanUrl;
        }

        yield put(balanceActions.fetchDisbursementAccountTrigger(account));
    } catch (e) {
        logger.log("fetch customer trigger failed", e);
    }
}
