import React from "react";
import {Container} from "./IconContainer.styled";
import {Icon} from "@opr-finance/component-icon";
import {Text} from "@opr-finance/component-fonts";
import {Redirect} from "react-router-dom";

export type IconContainerProps = {
    icon: string[];
    size: string;
    text: string;
    active: boolean | undefined;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function IconContainer(props: IconContainerProps) {
    return (
        <Container onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            if (props.onClick) {
                props.onClick(event);
            }
        }}>
            <div>
                <Icon icon={props.icon} size={props.size} color={props.active ? "#000" : "#6C7A80"} />
            </div>
            <div>
                <Text variant={"mobileNavigationIconLink"} paddingTop="4px">
                    {props.text}
                </Text>
            </div>
        </Container>
    );
}
