import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Logo } from "@opr-finance/component-logo";
import { Button } from "@opr-finance/component-button";
import { HeaderStyles } from "@opr-finance/theme-flex-online";
import { Wrapper } from "@opr-finance/component-wrapper";
import { StyledGrid } from "@opr-finance/component-grid";
import { Navigation } from "../Navigation";

import { HeaderProps } from "./types";

export const StyledHeader: FunctionComponent<HeaderProps> = (props) => {
    return (
        <StyledGrid
            styleConfig={{
                root: HeaderStyles.headerContainer(),
            }}
        >
            <StyledGrid
                styleConfig={{
                    root: HeaderStyles.headerStyles(),
                }}
            >
                {props.logo}
                <Navigation navigationItems={props.navigationItems} />
                {props.button}
            </StyledGrid>
        </StyledGrid>
    );
};
