import React from "react";
import { defineMessages } from "react-intl";
import { Text, InnerText } from "@opr-finance/component-fonts";
import { LinkTo } from "@opr-finance/component-link-to";

export const messages = defineMessages({
    pageTitle: {
        id: "page.front.title",
    },
    pageLoanSubtitle: {
        id: "page.front.loan.subtitle",
    },
    pageLoanInfo: {
        id: "page.front.loan.info",
    },
    pageLoanBalance: {
        id: "page.front.loan.balance",
    },
    pageLoanBottomInfo: {
        id: "page.front.loan.bottomInfo",
    },
    pageFrontNextInvoice: {
        id: "page.front.next.invoice.button",
    },
    pageWithdrawTitle: {
        id: "page.front.withdraw.title",
    },
    withdrawDone: {
        id: "page.front.withdraw.done",
    },
    withdrawError: {
        id: "page.front.withdraw.error",
    },
    withdrawListItem1: {
        id: "page.front.withdraw.list.item1",
        values: {
            maxLimit: (value) => value,
        },
    },
    withdrawListItem2: {
        id: "page.front.withdraw.list.item2",
    },
    withdrawListItem3: {
        id: "page.front.withdraw.list.item3",
    },
    withdrawListItem4: {
        id: "page.front.withdraw.list.item4",
    },
    withdrawTitle: {
        id: "page.front.withdraw.field.title",
        defaultMessage: "Translation missing",
    },
    withdrawButton: {
        id: "page.front.withdraw.button",
        defaultMessage: "Translation missing",
    },
    withdrawInfoMax: {
        id: "page.front.withdraw.validation.info.max",
        defaultMessage: "Translation missing",
    },
    withdrawInsufficientBalance: {
        id: "page.front.withdraw.validation.insufficient.balance",
        defaultMessage: "Translation missing",
    },
    withdrawIban: {
        id: "page.front.withdraw.iban",
        defaultMessage: "Translation missing",
        values: {
            strong: (value) => <InnerText variant="bold">{value}</InnerText>,
        },
    },
    balanceMin: {
        id: "page.front.balance.min",
        defaultMessage: "Translation missing",
    },
    balanceMax: {
        id: "page.front.balance.max",
        defaultMessage: "Translation missing",
    },
    recentNewsTitle: {
        id: "page.front.recentnews.title",
        defaultMessage: "Translation missing",
    },
    recentnewsReadMore: {
        id: "page.front.recentnews.read.more",
        defaultMessage: "Translation missing",
    },
    pageCollectionTitle: {
        id: "page.front.collection.title",
    },
    pageCollectionInfo: {
        id: "page.front.collection.info",
    },
    pageLoanButtonDocuments: {
        id: "page.loan.button.documents",
    },
    pageLoanDocumentsModalInfo: {
        id: "page.loan.documents.modal.info",
    },
    pageLoanDocumentsModalList: {
        id: "page.loan.documents.modal.list",
        values: {
            listitem: (value) => (
                <Text variant="body" listItem={true}>
                    {value}
                </Text>
            ),
        },
    },
    pageLoanDocumentsModalButton: {
        id: "page.loan.documents.modal.button",
    },
    pageCollectionCreditInfoTitle: {
        id: "page.loan.documents.credit.info.title",
    },
    collectionPagePhoneText: {
        id: "page.front.collection.phone.text",
    },
    collectionPagePhoneNumber: {
        id: "page.front.collection.phone.number",
    },
    collectionPageEmailText: {
        id: "page.front.collection.email.text",
    },
    collectionPageEmailLink: {
        id: "page.front.collection.email.link",
        values: {
            email: (value) => (
                <LinkTo variant={"light"} href={`mailto: ${value}`}>
                    {value}
                </LinkTo>
            ),
        },
    },
});
