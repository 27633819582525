import React from "react";
import { useSelector } from "react-redux";
import { BalanceTranslationProps, FeatureBalanceState } from "../types/balance";
import { Text } from "@opr-finance/component-fonts";
import { FlexColumn } from "@opr-finance/component-grid";
import { currency, CurrencyFormat, CurrencyLocale } from "@opr-finance/component-currency";
import {
    BalanceAmountContainer,
    BalanceAmountLeft,
    BalanceAmountRight,
    BalanceContainer,
    BalanceContent,
} from "./Balance.styled";

export function Balance(props: BalanceTranslationProps) {
    const balance = useSelector((state: FeatureBalanceState) => {
        return state.balance.balance;
    });

    let percentage = 0;

    if (balance) {
        percentage = ((balance.remainingPrincipal ?? 0) / (balance.creditLimit ?? 0)) * 100;
    }

    return (
        <FlexColumn alignItems="center" padding="0 16px 32px 16px">
            <BalanceContainer>
                <BalanceContent percentage={percentage} />
            </BalanceContainer>
            <BalanceAmountContainer>
                <BalanceAmountLeft>
                    <Text variant="small">{props.translation.minText}</Text>
                    <Text variant="small">
                        {currency({
                            value: balance?.remainingPrincipal,
                            locale: CurrencyLocale.Sweden,
                            currency: CurrencyFormat.Sweden,
                        })}
                    </Text>
                </BalanceAmountLeft>
                <BalanceAmountRight>
                    <Text variant="small">{props.translation.maxText}</Text>
                    <Text variant="small">
                        {currency({
                            value: balance?.creditLimit,
                            locale: CurrencyLocale.Sweden,
                            currency: CurrencyFormat.Sweden,
                        })}
                    </Text>
                </BalanceAmountRight>
            </BalanceAmountContainer>
        </FlexColumn>
    );
}
