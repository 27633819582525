import React from "react";
import { useIntl } from "react-intl";
import { FlexColumn, GridContainer, Grid, GridContent } from "@opr-finance/component-grid";
import { BoxContainer } from "@opr-finance/component-box-container";
import { PageTitle, SectionBox } from "@opr-finance/component-content";
import { Authenticator } from "@opr-finance/feature-lfp-login";
import { Text } from "@opr-finance/component-fonts";
import { messages } from "./messages";

export function ContactPage() {
    const { formatMessage: fm } = useIntl();
    return (
        <Authenticator>
            <FlexColumn>
                <PageTitle title={fm(messages.pageTitle)} />
                <GridContainer flex={1}>
                    <Grid width={1 / 2}>
                        <GridContent>
                            <BoxContainer>
                                <Text variant={"bodyTitle"}>
                                    {fm(messages.pageContactSubTitle)}
                                </Text>
                                <GridContainer>
                                    <Grid width={1 / 1}>
                                        <FlexColumn>
                                            <SectionBox variant={"contactPage"}>
                                                {fm(
                                                    messages.openingHours,
                                                    messages.openingHours.values
                                                )}
                                            </SectionBox>
                                            <SectionBox variant={"contactPage"}>
                                                {fm(
                                                    messages.phoneNumber,
                                                    messages.phoneNumber.values
                                                )}
                                            </SectionBox>
                                            <SectionBox variant={"contactPage"}>
                                                {fm(messages.email, messages.email.values)}
                                            </SectionBox>
                                            <SectionBox variant={"contactPage"}>
                                                {fm(messages.faq, messages.faq.values)}
                                            </SectionBox>
                                        </FlexColumn>
                                    </Grid>
                                </GridContainer>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                    <Grid width={1 / 2}>
                        <GridContent>
                            <BoxContainer>
                                <Text variant={"bodyTitle"}>{fm(messages.pageInfoSubTitle)}</Text>
                                <GridContainer>
                                    <Grid width={1 / 1}>
                                        <FlexColumn>
                                            <SectionBox variant={"contactPage"}>
                                                {fm(messages.pageInfo1, messages.pageInfo1.values)}
                                            </SectionBox>
                                            <SectionBox variant={"contactPage"}>
                                                {fm(messages.pageInfo2, messages.pageInfo2.values)}
                                            </SectionBox>
                                            <SectionBox variant={"contactPage"}>
                                                {fm(messages.pageInfo3, messages.pageInfo3.values)}
                                            </SectionBox>
                                        </FlexColumn>
                                    </Grid>
                                </GridContainer>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                </GridContainer>
            </FlexColumn>
        </Authenticator>
    );
}
