import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import {
    color,
    flexbox,
    layout,
    position,
    shadow,
    space,
    ColorProps,
    FlexboxProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    variant,
} from "styled-system";
import { AppTheme, AppThemeProps } from "@opr-finance/themes";

export type WrapperProps = ColorProps &
    FlexboxProps &
    LayoutProps &
    PositionProps &
    ShadowProps &
    SpaceProps & {
        variant:
            | "basic"
            | "centered"
            | "left"
            | "right"
            | "content"
            | "header"
            | "headerContent"
            | "headerNavigation"
            | "tabletNavigation"
            | "footer"
            | "footerColumn"
            | "footerTextColumn"
            | "footerRow"
            | "footerAfter";
        children: ReactNode;
    };

const variants = (theme: AppTheme) => ({
    basic: {
        display: "flex",
        width: "100%",
    },
    centered: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    left: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "left",
        justifySelf: "left",
        marginLeft: 0,
        marginRight: "auto",
        width: "auto",
    },
    right: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "right",
        justifySelf: "right",
        marginLeft: "auto",
        marginRight: 0,
        width: "auto",
    },
    content: {
        display: "flex",
        justifyContent: "center",
        pt: [64, 0],
        width: "100%",
    },
    header: {
        display: "flex",
        justifyContent: "center",
        minHeight: [56, 72],
        position: ["fixed", "initial"],
        width: "100%",
        backgroundColor: theme.appHeader.colors.background,
        boxShadow: theme.appHeader.boxShadow,
        flexWrap: "wrap",
        zIndex: 1001,
    },
    headerContent: {
        display: "flex",
        maxWidth: "960px",
        width: "90%",
    },
    headerNavigation: {
        width: "100%",
        justifyContent: "space-between",
        margin: "auto 34px auto 65px",
        display: ["none", "none", "flex"],
    },
    tabletNavigation: {
        width: "90%",
        justifyContent: "space-between",
        display: ["none", "flex", "none"],
        marginTop: "12px",
        marginBottom: "16px",
    },
    footer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: theme.footer.colors.background,
        pt: ["40px", "48px"],
        mt: "40px",
        width: "100%",
    },
    footerColumn: {
        display: "flex",
        flexDirection: "column",
        ml: ["30px", "30px", "0"],
    },
    footerTextColumn: {
        display: "flex",
        flexDirection: "column",
    },
    footerRow: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: "16px",
    },
    footerAfter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.footer.colors.secondary,
        width: "100%",
        height: "40px",
        mt: ["24px", "46px"],
    },
});

export const Wrapper: FunctionComponent<WrapperProps> = styled.div`
    ${(props: AppThemeProps) =>
        variant({
            variants: variants(props.theme),
        })}

    ${color}
    ${flexbox}
    ${layout}
    ${position}
    ${shadow}
    ${space}
`;
