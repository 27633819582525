import React from "react";
import { useIntl } from "react-intl";
import { Wrapper } from "@opr-finance/component-wrapper";
import { Text } from "@opr-finance/component-fonts";
import { Icon } from "@opr-finance/component-icon";
import { Logo } from "@opr-finance/component-logo";
import logo from "./images/EverydayPlusW.svg";
import { messages } from "./messages";
import { FooterColumns } from "@opr-finance/component-footer";

const Column1 = () => {
    const { formatMessage: fm } = useIntl();
    return (
        <Wrapper variant="footerColumn">
            <Text variant="footerTitle">{fm(messages.footerLinksTitle)}</Text>
            <Text variant="footerBody">
                {fm(messages.footerLinksPrivacy, messages.footerLinksPrivacy.values)}
            </Text>
            <Text variant="footerBody">
                {fm(messages.footerLinksCookies, messages.footerLinksCookies.values)}
            </Text>
        </Wrapper>
    );
};

const Column2 = () => {
    const { formatMessage: fm } = useIntl();
    return (
        <Wrapper variant="footerColumn">
            <Text variant="footerTitle">{fm(messages.footerCustomerServiceTitle)}</Text>
            <Wrapper variant="footerRow">
                <Text variant="iconWrapper" marginRight="16px">
                    <Icon icon={["far", "clock"]} size="2x" />
                </Text>
                <Wrapper variant="footerTextColumn">
                    {fm(
                        messages.footerCustomerServiceOpeningHours,
                        messages.footerCustomerServiceOpeningHours.values
                    )}
                </Wrapper>
            </Wrapper>
            <Wrapper variant="footerRow">
                <Text variant="iconWrapper" marginRight="16px">
                    <Icon icon={["fas", "phone-alt"]} size="2x" />
                </Text>
                <Wrapper variant="footerTextColumn">
                    <Text variant="footerBody">{fm(messages.footerCustomerServicePhone)}</Text>
                </Wrapper>
            </Wrapper>
            <Wrapper variant="footerRow">
                <Text variant="iconWrapper" marginRight="16px">
                    <Icon icon={["fas", "envelope"]} size="2x" />
                </Text>
                <Wrapper variant="footerTextColumn">
                    <Text variant="footerBody">
                        {fm(
                            messages.footerCustomerServiceEmail,
                            messages.footerCustomerServiceEmail.values
                        )}
                    </Text>
                </Wrapper>
            </Wrapper>
            <Wrapper variant="footerRow">
                <Text variant="iconWrapper" marginRight="16px">
                    <Icon icon={["fas", "question"]} size="2x" />
                </Text>
                <Wrapper variant="footerTextColumn">
                    <Text variant="footerBody">
                        {fm(
                            messages.footerCustomerServiceFaq,
                            messages.footerCustomerServiceFaq.values
                        )}
                    </Text>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

const Column3 = () => {
    const { formatMessage: fm } = useIntl();
    return (
        <Wrapper variant="footerColumn">
            <Text variant="footerTitle">{fm(messages.footerContactTitle)}</Text>
            <Wrapper variant="footerRow">
                <Text variant="iconWrapper" marginRight="16px">
                    <Icon icon={["far", "address-book"]} size="2x" />
                </Text>
                <Wrapper variant="footerTextColumn">
                    {fm(messages.footerContactAddress, messages.footerContactAddress.values)}
                </Wrapper>
            </Wrapper>
            <Wrapper variant="footerRow">
                <Text variant="iconWrapper" marginRight="16px"></Text>
                <Wrapper variant="footerTextColumn">
                    {fm(messages.footerContactInfo, messages.footerContactInfo.values)}
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export const FooterContent: FooterColumns = [
    { content: () => Column1(), order: [2, 1], marginBottom: ["16px", "0px"] },
    { content: () => Column2(), order: [1, 2], marginBottom: ["16px", "0ox"] },
    { content: () => Column3(), order: [3] },
];

export const AfterFooter = () => (
    <Wrapper variant="footerAfter">
        <Logo logoSrc={logo} width={94} mt={"2px"} />
    </Wrapper>
);
