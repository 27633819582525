import { ActionType, createAction } from "typesafe-actions";
import {
    BalanceActionConstants,
    BalanceInitializerType,
    T_VpDisbursementAccount,
    T_VpRevolvingAccount,
} from "../types/balance";

export const balanceActions = {
    balanceInitializer: createAction(
        BalanceActionConstants.BALANCE_INITIALIZER
    )<BalanceInitializerType>(),
    fetchAccountTrigger: createAction(BalanceActionConstants.FETCH_ACCOUNT_TRIGGER)(),
    fetchAccountSuccess: createAction(
        BalanceActionConstants.FETCH_ACCOUNT_SUCCESS
    )<T_VpRevolvingAccount>(),
    fetchAccountError: createAction(BalanceActionConstants.FETCH_ACCOUNT_SUCCESS)(),
    fetchDisbursementAccountTrigger: createAction(
        BalanceActionConstants.FETCH_DISBURSEMENT_ACCOUNT_TRIGGER
    )<T_VpRevolvingAccount>(),
    fetchDisbursementAccountSuccess: createAction(
        BalanceActionConstants.FETCH_DISBURSEMENT_ACCOUNT_SUCCESS
    )<T_VpDisbursementAccount>(),
    getAccountTrigger: createAction(
        BalanceActionConstants.GET_ACCOUNT_TRIGGER
    )<T_VpRevolvingAccount>(),
};

export type BalanceAction = ActionType<typeof balanceActions>;
