import { ActionType, createAction } from "typesafe-actions";

export enum AppActionConstants {
    START_PAGE_TRIGGER = "APP/START_PAGE_TRIGGER",
    FRONT_PAGE_TRIGGER = "APP/FRONT_PAGE_TRIGGER",
    USER_PAGE_TRIGGER = "APP/USER_PAGE_TRIGGER",
    LOGIN_PAGE_TRIGGER = "APP/LOGIN_PAGE_TRIGGER",
    CHANGE_PAGE_TRIGGER = "APP/CHANGE_PAGE_TRIGGER",
    LOAN_PAGE_TRIGGER = "APP/LOAN_PAGE_TRIGGER",
    LOGOUT_PAGE_TRIGGER = "APP/LOGOUT_PAGE_TRIGGER",
}

export const appActions = {
    startPageTrigger: createAction(AppActionConstants.START_PAGE_TRIGGER)(),
    frontPageTrigger: createAction(AppActionConstants.FRONT_PAGE_TRIGGER)(),
    userPageTrigger: createAction(AppActionConstants.USER_PAGE_TRIGGER)(),
    loginPageTrigger: createAction(AppActionConstants.LOGIN_PAGE_TRIGGER)(),
    changePageTrigger: createAction(AppActionConstants.CHANGE_PAGE_TRIGGER)(),
    loanPageTrigger: createAction(AppActionConstants.LOAN_PAGE_TRIGGER)(),
    logoutPageTriggre: createAction(AppActionConstants.LOGOUT_PAGE_TRIGGER)(),
};

export type AppAction = ActionType<typeof appActions>;
