import { takeEvery, put } from "redux-saga/effects";
import { AppActionConstants } from "../actions/actions";
import { loginActions } from "@opr-finance/feature-lfp-login";
import { errorActions } from "@opr-finance/feature-error";

export function* watchLoginPageTrigger() {
    yield takeEvery(AppActionConstants.LOGIN_PAGE_TRIGGER, handleLoginPageTrigger);
}

export function* handleLoginPageTrigger() {
    try {
        console.log("login page trigger!");

        yield put(loginActions.loginComplete());
    } catch (e) {
        console.log("fetch login page trigger failed");
        yield put(errorActions.errorTrigger({ message: "login failed", url: "/error" }));
    }
}
