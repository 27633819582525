import { takeEvery, put, take, select } from "redux-saga/effects";
import { AppActionConstants } from "../actions/actions";
import { loginActions } from "@opr-finance/feature-lfp-login";
import { balanceActions } from "@opr-finance/feature-balance";
import { statementsActions } from "@opr-finance/feature-statements";
import { errorActions } from "@opr-finance/feature-error";
import { AppState } from "../types/general";

export function* watchChangePageTrigger() {
    yield takeEvery(AppActionConstants.CHANGE_PAGE_TRIGGER, handleChangePageTrigger);
}

export function* handleChangePageTrigger() {
    try {
        yield put(loginActions.loginCheckStatus());
        yield take(loginActions.loginCompleteSuccess);
        const activeAccount = yield select((state: AppState) => state.activeAccount.account);
        if (!activeAccount) {
            yield put(balanceActions.fetchAccountTrigger());
        } else {
            yield put(balanceActions.getAccountTrigger(activeAccount));
        }
        yield take(balanceActions.fetchAccountSuccess);
        yield put(statementsActions.fetchStatementsTrigger());
        yield take(statementsActions.fetchStatementsSuccess);
    } catch (e) {
        console.log("fetch change page trigger failed");
        yield put(errorActions.errorTrigger({ message: "change page load failed", url: "/error" }));
    }
}
