import React from "react";
import { defineMessages } from "react-intl";
import { LinkTo } from "@opr-finance/component-link-to";
import { Text } from "@opr-finance/component-fonts";

export const messages = defineMessages({
    footerLinksTitle: {
        id: "footer.links.title",
    },
    footerLinksPrivacy: {
        id: "footer.links.privacy",
        values: {
            link: (value) => {
                const attrs = value[0].split("|");
                return (
                    <LinkTo variant="basic" href={attrs[1]}>
                        {attrs[0]}
                    </LinkTo>
                );
            },
        },
    },
    footerLinksCookies: {
        id: "footer.links.cookies",
        values: {
            link: (value) => {
                const attrs = value[0].split("|");
                return (
                    <LinkTo variant="basic" href={attrs[1]}>
                        {attrs[0]}
                    </LinkTo>
                );
            },
        },
    },
    footerCustomerServiceTitle: {
        id: "footer.customerservice.title",
    },
    footerCustomerServiceOpeningHours: {
        id: "footer.customerservice.opening.hours",
        values: {
            line: (value) => <Text variant={"footerBody"}>{value}</Text>,
        },
    },
    footerCustomerServicePhone: {
        id: "footer.customerservice.phone",
    },
    footerCustomerServiceEmail: {
        id: "footer.customerservice.email",
        values: {
            email: (value) => (
                <LinkTo variant={"basic"} href={`mailto: ${value}`}>
                    {value}
                </LinkTo>
            ),
        },
    },
    footerCustomerServiceFaq: {
        id: "footer.customerservice.faq",
        values: {
            link: (value) => {
                const attrs = value[0].split("|");
                return (
                    <LinkTo variant="basic" href={attrs[1]}>
                        {attrs[0]}
                    </LinkTo>
                );
            },
        },
    },
    footerContactTitle: {
        id: "footer.contact.title",
    },
    footerContactAddress: {
        id: "footer.contact.address",
        values: {
            line: (value) => <Text variant={"footerBody"}>{value}</Text>,
        },
    },
    footerContactInfo: {
        id: "footer.contact.info",
        values: {
            line: (value) => <Text variant={"footerBody"}>{value}</Text>,
        },
    },
});
