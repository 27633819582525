import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Authenticator } from "@opr-finance/feature-lfp-login";
import { BoxContainer } from "@opr-finance/component-box-container";
import { Grid, GridContent, GridContainer, FlexColumn } from "@opr-finance/component-grid";
import { PageTitle, SectionBox } from "@opr-finance/component-content";
import { Text } from "@opr-finance/component-fonts";
import { LinkTo } from "@opr-finance/component-link-to";
import { Button } from "@opr-finance/component-button";
import { Icon } from "@opr-finance/component-icon";
import { Wrapper } from "@opr-finance/component-wrapper";
import { GenericTable } from "../../components/GenericTable/GenericTable";
import { documentActions, DocumentType } from "@opr-finance/feature-document";
import { ModalDialog } from "@opr-finance/component-modal-dialog";
import { Balance } from "@opr-finance/feature-balance";
import { DateFormatter } from "@opr-finance/component-date";
import { FormattedStatement } from "@opr-finance/feature-statements";
import { AppState } from "../../types/general";
import { messages } from "./messages";
import { FormattedTransactions } from "@opr-finance/feature-transactions";
import { GiosgData } from "../../components/GiosgData/GiosgData";
import { currency, CurrencyFormat, CurrencyLocale } from "@opr-finance/component-currency";
import { Selector } from "react-data-table-component";

export function LoanPage() {
    const { formatMessage: fm } = useIntl();
    const statementsData = useSelector((state: AppState) => state.statements.formatted);
    const transactionsData = useSelector((state: AppState) => state.transactions.formatted);

    const history = useHistory();

    const dispatch = useDispatch();
    const { user, balance, disbursementAccount, promissoryNoteId } = useSelector(
        (state: AppState) => {
            return {
                user: state.customer.user,
                balance: state.balance.balance,
                disbursementAccount: state.balance.disbursementAccount,
                promissoryNoteId: state.document.documentId,
            };
        }
    );

    const availableBalance =
        balance && balance.creditLimit && typeof balance.remainingPrincipal === "number"
            ? balance.creditLimit - balance.remainingPrincipal
            : 0;

    const getPdfClick = (event, documentId, documentType) => {
        event.preventDefault();
        console.log("documentId", documentId);
        dispatch(
            documentActions.fetchDocumentTrigger({
                documentId,
                documentType,
            })
        );
    };

    const [isLoanInfoModalOpen, setIsLoanInfoModalOpen] = useState(false);
    const [isLoanDocumentModalOpen, setIsLoanDocumentModalOpen] = useState(false);

    const getLinkVariant = (data: FormattedStatement) => {
        switch (data.status) {
            case "OVERDUE":
                return "tertiary";
            case "UNPAID":
                return "secondary";
            default:
                return "primary";
        }
    };

    const configureInvoicesColumns = () => {
        return [
            {
                name: fm(messages.pageLoanInvoicesTableInvoiceNumber),
                cell: (rowData: FormattedStatement) => (
                    <LinkTo
                        variant={getLinkVariant(rowData)}
                        onClick={(event) =>
                            getPdfClick(event, rowData.documentId, DocumentType.INVOICE)
                        }
                        target="_blank">
                        {rowData.seriesNum}
                    </LinkTo>
                ),
            },
            {
                name: fm(messages.pageLoanInvoicesTableInvoiceTitle),
                cell: (rowData: FormattedStatement) => (
                    <LinkTo
                        variant={getLinkVariant(rowData)}
                        onClick={(event) =>
                            getPdfClick(event, rowData.documentId, DocumentType.INVOICE)
                        }
                        target="_blank">
                        {rowData.title}
                    </LinkTo>
                ),
            },
            {
                name: fm(messages.pageLoanInvoicesTableInvoiceStatus),
                cell: (rowData: FormattedStatement) =>
                    rowData.status && messages.hasOwnProperty(rowData.status)
                        ? fm(messages[rowData.status])
                        : fm(messages.defaultStatementTypeMessage),
            },
            {
                name: fm(messages.pageLoanInvoicesTableInvoiceDuedate),
                selector: (rowData: FormattedStatement) => (rowData.dueDate ? rowData.dueDate : ""),
            },
            {
                name: fm(messages.pageLoanInvoicesTableInvoiceAmount),
                selector: (rowData: FormattedStatement) => rowData.amount,
            },
        ];
    };

    if (!balance) {
        return null;
    }

    if (!user) {
        return null;
    }

    // const promissaryNoteId: string = balance.promissoryNoteDocumentId as string;
    const configureInvoicesMobileColumns = () => {
        return [
            {
                name: "",
                cell: (rowData: FormattedStatement) => {
                    const statementStatus =
                        rowData.status && messages.hasOwnProperty(rowData.status)
                            ? fm(messages[rowData.status])
                            : fm(messages.defaultStatementTypeMessage);
                    return (
                        <Wrapper variant="basic" marginRight={"8px"}>
                            <Wrapper variant="left" flexDirection={"column"}>
                                <LinkTo
                                    variant={getLinkVariant(rowData)}
                                    onClick={(event) =>
                                        getPdfClick(event, rowData.documentId, DocumentType.INVOICE)
                                    }
                                    target="_blank"
                                    fontWeight="bold">
                                    {rowData.title}
                                </LinkTo>
                                <Text variant="smaller" marginTop={"8px"}>
                                    {statementStatus}
                                </Text>
                                <Text variant="smaller" marginTop={"8px"}>
                                    {rowData.dueDate}
                                </Text>
                            </Wrapper>
                            <Wrapper variant="right" alignItems={"center"}>
                                <Text variant="smallerBold">{rowData.amount}</Text>
                            </Wrapper>
                        </Wrapper>
                    );
                },
            },
        ];
    };

    const configureTransactionsColumns = () => {
        return [
            {
                name: fm(messages.pageLoanTransactionsTableDate),
                selector: (rowData: FormattedTransactions) => rowData.formattedDate,
            },
            {
                name: fm(messages.pageLoanTransactionsTableType),
                cell: (rowData: FormattedTransactions) =>
                    rowData.transactionType && messages.hasOwnProperty(rowData.transactionType)
                        ? fm(messages[rowData.transactionType])
                        : fm(messages.defaultTransactionTypeMessage),
            },
            {
                name: fm(messages.pageLoanTransactionsTableAmount),
                selector: (rowData: FormattedTransactions) => rowData.formattedAmount,
            },
        ];
    };
    const configureTransactionsMobileColumns = () => {
        return [
            {
                name: "",
                cell: (rowData) => {
                    const cellTitle =
                        rowData.transactionType && messages.hasOwnProperty(rowData.transactionType)
                            ? fm(messages[rowData.transactionType])
                            : fm(messages.defaultTransactionTypeMessage);
                    return (
                        <Wrapper variant="basic" marginRight={"8px"}>
                            <Wrapper variant="left" flexDirection={"column"}>
                                <Text variant="smaller">{cellTitle}</Text>
                                <Text variant="smaller" marginTop={"8px"}>
                                    {rowData.formattedDate}
                                </Text>
                            </Wrapper>
                            <Wrapper variant="right" alignItems={"center"}>
                                <Text variant="smallerBold">{rowData.formattedAmount}</Text>
                            </Wrapper>
                        </Wrapper>
                    );
                },
            },
        ];
    };

    if (balance.state === "COLLECTION") {
        history.push("/front");
    }

    return (
        <Authenticator>
            <FlexColumn>
                <PageTitle title={fm(messages.pageTitle)} />
                <GridContainer flex="1">
                    <Grid width={2 / 4}>
                        <GridContent>
                            <BoxContainer>
                                <SectionBox variant="basicCentered">
                                    <Text variant="bodyTitle" fontSize="24px">
                                        {fm(messages.pageLoanSubTitle)}
                                    </Text>
                                    <Text variant="body">
                                        {fm(messages.pageLoanInfoTodayDate)} <DateFormatter />
                                    </Text>
                                    <Text variant="bodyTitle" fontSize="20px">
                                        {currency({
                                            value: availableBalance,
                                            locale: CurrencyLocale.Sweden,
                                            currency: CurrencyFormat.Sweden,
                                        })}
                                    </Text>
                                    <Text
                                        variant="body"
                                        width="100%"
                                        maxWidth="320px"
                                        marginBottom="32px"
                                        textAlign="center">
                                        {fm(messages.pageLoanInfo)}
                                    </Text>
                                    <Wrapper variant="centered" flexDirection={"column"}>
                                        <Button
                                            variant="secondary"
                                            marginBottom="22px"
                                            width={"100%"}
                                            onClick={() => {
                                                setIsLoanInfoModalOpen(true);
                                            }}>
                                            {fm(messages.pageLoanButtonOverview)}
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            width={"100%"}
                                            marginBottom="52px"
                                            onClick={() => {
                                                setIsLoanDocumentModalOpen(true);
                                            }}>
                                            {fm(messages.pageLoanButtonDocuments)}
                                        </Button>
                                    </Wrapper>
                                </SectionBox>
                                <ModalDialog
                                    modalTitle={fm(messages.pageLoanButtonOverview)}
                                    isOpen={isLoanInfoModalOpen}
                                    onClick={() => {
                                        setIsLoanInfoModalOpen(false);
                                    }}
                                    dialogVariant="default">
                                    <SectionBox variant="basicCentered">
                                        <Text variant="body">
                                            {fm(messages.pageLoanOverviewModalSubTitle)}
                                        </Text>
                                        <Text variant="bodyTitle">
                                            {currency({
                                                value: availableBalance,
                                                locale: CurrencyLocale.Sweden,
                                                currency: CurrencyFormat.Sweden,
                                            })}
                                        </Text>
                                        <Text variant="body" marginBottom="8px">
                                            {fm(messages.pageLoanOverviewModalBalance)}
                                        </Text>
                                        <Text variant="small" marginBottom="24px">
                                            <DateFormatter />
                                        </Text>
                                    </SectionBox>
                                    <Balance
                                        translation={{
                                            minText: fm(messages.balanceMin),
                                            maxText: fm(messages.balanceMax),
                                        }}
                                    />
                                    <SectionBox variant="basicCentered">
                                        <Text
                                            variant="body"
                                            width="100%"
                                            maxWidth="360px"
                                            marginBottom="32px">
                                            {fm(messages.pageLoanOverviewModalCreditInfo)}
                                        </Text>
                                    </SectionBox>
                                    <GridContainer>
                                        <Grid width={1 / 2}>
                                            <FlexColumn>
                                                <Text variant={"loanModalUserTitle"}>
                                                    {fm(messages.pageLoanOverviewModalName)}
                                                </Text>
                                                <Text variant={"loanModalUserBody"}>
                                                    {user?.customerBasicInfo?.givenName}{" "}
                                                    {user?.customerBasicInfo?.surName}
                                                </Text>
                                                <Text variant={"loanModalUserTitle"}>
                                                    {fm(
                                                        messages.pageLoanOverviewModalCustomerNumber
                                                    )}
                                                </Text>
                                                <Text variant={"loanModalUserBody"}>
                                                    {user?.customerBasicInfo?.customerId}
                                                </Text>
                                                <Text variant={"loanModalUserTitle"}>
                                                    {fm(messages.pageLoanOverviewModalCustomerId)}
                                                </Text>
                                                <Text
                                                    variant={"loanModalUserBody"}
                                                    paddingBottom={"28px"}>
                                                    {user?.customerBasicInfo?.ssn}
                                                </Text>
                                            </FlexColumn>
                                        </Grid>
                                        <Grid width={1 / 2}>
                                            <FlexColumn>
                                                <Text variant={"loanModalUserTitle"}>
                                                    {fm(messages.pageLoanOverviewModalIban)}
                                                </Text>
                                                <Text variant={"loanModalUserBody"}>
                                                    {disbursementAccount?.number}
                                                </Text>
                                                <Text variant={"loanModalUserTitle"}>
                                                    {fm(messages.pageLoanOverviewModalCreditTaken)}
                                                </Text>
                                                <Text variant={"loanModalUserBody"}>
                                                    <DateFormatter date={balance.createDate} />
                                                </Text>
                                            </FlexColumn>
                                        </Grid>
                                    </GridContainer>
                                </ModalDialog>
                                <ModalDialog
                                    modalTitle={fm(messages.pageLoanButtonDocuments)}
                                    isOpen={isLoanDocumentModalOpen}
                                    onClick={() => {
                                        setIsLoanDocumentModalOpen(false);
                                    }}
                                    dialogVariant="default">
                                    <Text variant="body">
                                        {fm(messages.pageLoanDocumentsModalInfo)}
                                    </Text>
                                    <SectionBox flexDirection={"column"} marginTop={"8px"}>
                                        {fm(
                                            messages.pageLoanDocumentsModalList,
                                            messages.pageLoanDocumentsModalList.values
                                        )}
                                    </SectionBox>
                                    <Wrapper variant="centered">
                                        <Button
                                            variant="secondary"
                                            onClick={(event) =>
                                                getPdfClick(
                                                    event,
                                                    promissoryNoteId,
                                                    DocumentType.PROMISSORY_NOTE
                                                )
                                            }
                                            marginTop={"24px"}
                                            alignSelf={"center"}>
                                            {fm(messages.pageLoanDocumentsModalButton)}
                                        </Button>
                                    </Wrapper>
                                </ModalDialog>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                    <Grid width={2 / 4}>
                        <GridContent>
                            <BoxContainer>
                                <SectionBox variant="basicCentered">
                                    <Text variant="bodyTitle" fontSize="24px" paddingBottom="32px">
                                        {fm(messages.pageLoanPayCreditSubtitle)}
                                    </Text>
                                    <Text variant="body" marginBottom="40px" maxWidth="420px">
                                        {fm(messages.pageLoanPayCreditInfo)}
                                    </Text>
                                    <Text variant="body" marginBottom="16px">
                                        {fm(
                                            messages.pageLoanPayCreditPhone,
                                            messages.pageLoanPayCreditPhone.values
                                        )}
                                    </Text>
                                    <Text variant="body" marginBottom="16px">
                                        {fm(
                                            messages.pageLoanPayCreditEmail,
                                            messages.pageLoanPayCreditEmail.values
                                        )}
                                    </Text>
                                    <SectionBox flexDirection="column">
                                        {fm(
                                            messages.pageLoanPayCreditOpeningHours,
                                            messages.pageLoanPayCreditOpeningHours.values
                                        )}
                                    </SectionBox>
                                </SectionBox>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GridContainer display={["flex", "none"]}>
                    <Grid width={1 / 1} height={"auto"}>
                        <GridContent alignItems={"center"} justifyContent={"center"}>
                            <Button
                                variant="large"
                                onClick={() => {
                                    history.push("/front");
                                }}
                                paddingY={"24px"}
                                width="100%">
                                <Text variant="largeButtonTitle">
                                    {fm(messages.pageLoanTransactionsButton)}
                                </Text>
                                <Icon
                                    icon={["far", "money-bill-alt"]}
                                    size="4x"
                                    variant="largeButton"
                                    marginTop={"17px"}
                                />
                            </Button>
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GridContainer>
                    <Grid width={"100%"}>
                        <GridContent>
                            <BoxContainer padding={["8px", "16px"]}>
                                <GridContainer>
                                    <Grid width={2 / 3} flexDirection={"column"}>
                                        <Text variant="bodyTitle">
                                            {fm(messages.pageLoanTransactionsTableTitle)}
                                        </Text>
                                        <GridContent display={["none", "flex"]}>
                                            <GenericTable
                                                columns={configureTransactionsColumns()}
                                                data={transactionsData}
                                            />
                                        </GridContent>
                                        <GridContent display={["flex", "none"]}>
                                            <GenericTable
                                                columns={configureTransactionsMobileColumns()}
                                                data={transactionsData}
                                                noTableHead={true}
                                            />
                                        </GridContent>
                                    </Grid>
                                    <Grid width={1 / 3} height={"auto"} display={["none", "flex"]}>
                                        <GridContent
                                            alignItems={"center"}
                                            justifyContent={"center"}>
                                            <Button
                                                variant="large"
                                                onClick={() => {
                                                    history.push("/front");
                                                }}
                                                padding={"52px"}
                                                marginLeft={"24px"}>
                                                <Text variant="largeButtonTitle">
                                                    {fm(messages.pageLoanTransactionsButton)}
                                                </Text>
                                                <Icon
                                                    icon={["far", "money-bill-alt"]}
                                                    size="4x"
                                                    variant="largeButton"
                                                    marginTop={"26px"}
                                                />
                                            </Button>
                                        </GridContent>
                                    </Grid>
                                </GridContainer>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GridContainer>
                    <Grid width="100%">
                        <GridContent>
                            <BoxContainer padding={["8px", "16px"]}>
                                <Text variant="bodyTitle" textAlign={["center", "left"]}>
                                    {fm(messages.pageLoanInvoicesTableTitle)}
                                </Text>
                                <GridContent display={["none", "flex"]}>
                                    <GenericTable
                                        columns={configureInvoicesColumns()}
                                        data={statementsData}
                                    />
                                </GridContent>
                                <GridContent display={["flex", "none"]}>
                                    <GenericTable
                                        columns={configureInvoicesMobileColumns()}
                                        data={statementsData}
                                        noTableHead={true}
                                    />
                                </GridContent>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GiosgData
                    customerName={`${user?.customerBasicInfo?.givenName} ${user?.customerBasicInfo?.surName}`}
                    customerId={user?.customerBasicInfo?.customerId}
                />
            </FlexColumn>
        </Authenticator>
    );
}
