import React, { ReactNode } from "react";
import {
    Container,
    Links,
    LinksTitle,
    Title,
    MorePageCloseText,
    MorePageTitleText,
    RestContainer,
} from "./MorePage.styled";
import { Text } from "@opr-finance/component-fonts";

export type LinkContainerProps = {
    linksTitle: string;
    links: ReactNode;
};

export function LinkContainer(props: LinkContainerProps) {
    return (
        <Links>
            <LinksTitle>
                <Text variant="mobileNavigationLinksDescription">{props.linksTitle}</Text>
            </LinksTitle>
            {props.links}
        </Links>
    );
}

export type MorePageProps = {
    title: string;
    header?: ReactNode;
    after?: ReactNode;
    isVisible: boolean;
    onCloseClick: (event: React.MouseEvent<HTMLDivElement>) => void;
} & LinkContainerProps;

export function MorePage(props: MorePageProps) {
    if (!props.isVisible) {
        return null;
    }
    return (
        <Container>
            {props.header}
            <Title>
                <MorePageTitleText>
                    <Text variant={"mobileNavigationTitle"}>{props.title}</Text>
                </MorePageTitleText>
                <MorePageCloseText>
                    <Text variant={"mobileNavigationTitle"} onClick={props.onCloseClick}>
                        X
                    </Text>
                </MorePageCloseText>
            </Title>
            <LinkContainer linksTitle={props.linksTitle} links={props.links} />
            <RestContainer>{props.after}</RestContainer>
        </Container>
    );
}
