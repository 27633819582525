import React from "react";
import { defineMessages } from "react-intl";
import { LinkTo } from "@opr-finance/component-link-to";
import { Text } from "@opr-finance/component-fonts";

export const messages = defineMessages({
    pageTitle: {
        id: "page.contact.title",
    },
    pageContactSubTitle: {
        id: "page.contact.subtitle",
    },
    openingHours: {
        id: "page.contact.opening.hours",
        values: {
            title: (value) => <Text variant={"contactPageTitle"}>{value}</Text>,
            line: (value) => <Text variant={"contactPageBody"}>{value}</Text>,
        },
    },
    phoneNumber: {
        id: "page.contact.phone.number",
        values: {
            title: (value) => <Text variant={"contactPageTitle"}>{value}</Text>,
            line: (value) => <Text variant={"contactPageBody"}>{value}</Text>,
        },
    },
    email: {
        id: "page.contact.email",
        values: {
            title: (value) => <Text variant={"contactPageTitle"}>{value}</Text>,
            line: (value) => <Text variant={"contactPageBody"}>{value}</Text>,
            email: (value) => (
                <Text variant={"contactPageBody"}>
                    <LinkTo variant={"basic"} href={`mailto: ${value}`}>
                        {value}
                    </LinkTo>
                </Text>
            ),
        },
    },
    faq: {
        id: "page.contact.faq",
        values: {
            title: (value) => <Text variant="contactPageTitle">{value}</Text>,
            line: (value) => <Text variant={"contactPageBody"}>{value}</Text>,
            link: (value) => {
                const attrs = value[0].split("|");
                return (
                    <Text variant={"contactPageBody"}>
                        <LinkTo variant="basic" href={attrs[1]}>
                            {attrs[0]}
                        </LinkTo>
                    </Text>
                );
            },
        },
    },
    pageInfoSubTitle: {
        id: "page.contact.info.title",
    },
    pageInfo1: {
        id: "page.contact.info1",
        values: {
            title: (value) => <Text variant="contactPageTitle">{value}</Text>,
            line: (value) => <Text variant="contactPageBody">{value}</Text>,
            email: (value) => (
                <LinkTo variant="basic" href={`mailto: ${value}`}>
                    {value}
                </LinkTo>
            ),
        },
    },
    pageInfo2: {
        id: "page.contact.info2",
        values: {
            title: (value) => <Text variant={"contactPageTitle"}>{value}</Text>,
            line: (value) => <Text variant={"contactPageBody"}>{value}</Text>,
            email: (value) => (
                <LinkTo variant="basic" href={`mailto: ${value}`}>
                    {value}
                </LinkTo>
            ),
        },
    },
    pageInfo3: {
        id: "page.contact.info3",
        values: {
            title: (value) => <Text variant={"contactPageTitle"}>{value}</Text>,
            line: (value) => <Text variant={"contactPageBody"}>{value}</Text>,
        },
    },
});
