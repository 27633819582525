import React from "react";
import { useIntl } from "react-intl";
import { Table, TData, TColumns } from "@opr-finance/component-table";
import { Icon } from "@opr-finance/component-icon";
import { everydayPlusTheme } from "@opr-finance/themes";
import { messages } from "./messages";

export type GenericTableProps<T> = {
    data: TData<T>;
    columns: TColumns<T>;
    noTableHead?: boolean;
};

export function GenericTable<T>(props: GenericTableProps<T>) {
    const { formatMessage: fm } = useIntl();
    return (
        <Table
            leftIcon={<Icon icon={["fas", "caret-left"]} />}
            rightIcon={<Icon icon={["fas", "caret-right"]} />}
            pagesIcon={<Icon icon={["fas", "caret-down"]} />}
            translation={{ labelPrefix: fm(messages.show), noDataText: fm(messages.noData) }}
            itemsPerPage={6}
            theme={everydayPlusTheme}
            columns={props.columns}
            data={props.data}
            noTableHead={props.noTableHead}
        />
    );
}
