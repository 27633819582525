import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { loginActions } from "@opr-finance/feature-lfp-login";
import { FlexColumn, GridContainer, Grid, GridContent } from "@opr-finance/component-grid";
import { BoxContainer } from "@opr-finance/component-box-container";
import { PageTitle } from "@opr-finance/component-content";
import { Text } from "@opr-finance/component-fonts";
import { Wrapper } from "@opr-finance/component-wrapper";
import { Button } from "@opr-finance/component-button";
import logo from "../../images/bitmap.png";
import { messages } from "./messages";

export function StartPage() {
    const dispatch = useDispatch();
    const { formatMessage: fm } = useIntl();

    return (
        <FlexColumn>
            <PageTitle title={fm(messages.pageStartTitle)} isTitleCentered={true} />
            <GridContainer>
                <Grid width={"100%"}>
                    <GridContent>
                        <BoxContainer
                            onClick={() => {
                                dispatch(
                                    loginActions.loginStart({
                                        method: "ftn-op-auth",
                                    })
                                );
                            }}>
                            <Wrapper variant="centered" mb={"30px"}>
                                <Text variant={"bodyTitle"} fontSize={"24px"}>
                                    {fm(messages.pageStartLoginInfo)}
                                </Text>
                            </Wrapper>
                            <Wrapper variant="centered" mb={"80px"}>
                                <Button variant={"large"} width={"212px"} height={"172px"}>
                                    <Text variant={"body"} marginBottom={"16px"}>
                                        {fm(messages.pageStartLoginButton)}
                                    </Text>
                                    <img src={logo} alt={""} />
                                </Button>
                            </Wrapper>
                        </BoxContainer>
                    </GridContent>
                </Grid>
            </GridContainer>
        </FlexColumn>
    );
}
