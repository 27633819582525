import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { BalanceAction, balanceActions } from "../actions/balance";
import { BalanceReducerState } from "../types/balance";

export const initialState = {
    balance: null,
    disbursementAccount: null,
    config: {
        mockApiCalls: false,
        gwUrl: "",
        token: "",
        noLoanUrl: "",
        accountId: undefined,
    },
};

export const balanceReducer = createReducer<BalanceReducerState, BalanceAction>(initialState)
    .handleAction(balanceActions.fetchAccountSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.balance = action.payload;
        });
    })
    .handleAction(balanceActions.fetchDisbursementAccountSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.disbursementAccount = action.payload;
        });
    })
    .handleAction(balanceActions.balanceInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config.mockApiCalls = action.payload.mockApiCalls;
            draftState.config.gwUrl = action.payload.gwUrl;
            draftState.config.token = action.payload.token;
            draftState.config.noLoanUrl = action.payload.noLoanUrl;
            draftState.config.accountId = action.payload.accountId;
        });
    })
    .handleAction(balanceActions.getAccountTrigger, (state, action) => {
        return produce(state, (draftState) => {
            draftState.balance = action.payload;
        });
    });
