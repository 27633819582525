import { defineMessages } from "react-intl";

export const appMessages = defineMessages({
    navigationFront: {
        id: "navigation.front",
    },
    navigationLan: {
        id: "navigation.loan",
    },
    navigationChange: {
        id: "navigation.change",
    },
    navigationUser: {
        id: "navigation.user",
    },
    navigationCustomerservice: {
        id: "navigation.customerservice",
    },
    mobileNavigationMore: {
        id: "navigation.mobile.more",
    },
    mobileNavigationMoreTitle: {
        id: "navigation.mobile.more.title",
    },
    mobileNavigationMorePage: {
        id: "navigation.mobile.more.title",
    },
    mobileNavigationMorePageUser: {
        id: "navigation.mobile.more.page.user",
    },
    mobileNavigationMorePageCustomerService: {
        id: "navigation.mobile.more.page.customerservice",
    },
    navigationBackLink: {
        id: "navigation.marketing.page.link.text",
    },
});
