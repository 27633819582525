import React from "react";
import { defineMessages } from "react-intl";
import {
    TransactionStatuses,
    generateMessageObject,
    GeneratedMessages,
    MessageData,
} from "@opr-finance/feature-transactions";
import {
    StatementsMessageData,
    StatementsGeneratedMessages,
    StatemnetStatuses,
    generateStatementsMessageObject,
} from "@opr-finance/feature-statements";
import { Text, InnerText } from "@opr-finance/component-fonts";
import { LinkTo } from "@opr-finance/component-link-to";

const transactionStatusesdata: MessageData[] = [
    {
        contentfulKey: "page.loan.transactions.table.title.loan.adjustment.interest",
        messageId: TransactionStatuses.LOAN_ADJUSTMENT_INTEREST_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.disbursement",
        messageId: TransactionStatuses.LOAN_TRANCHE_DISBURSEMENT_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.overpayment.after.last.statement",
        messageId: TransactionStatuses.OVER_PAYMENT_AFTER_LAST_STATEMENT_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.payback.to.customer.from.account",
        messageId: TransactionStatuses.PAYBACK_TO_CUSTOMER_FROM_ACCOUNT_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.payment",
        messageId: TransactionStatuses.PAYMENT_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transaction.table.title.statement.amortization",
        messageId: TransactionStatuses.STATEMENT_AMORTIZATION_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.statement.fee",
        messageId: TransactionStatuses.STATEMENT_FEE_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.statement.interest",
        messageId: TransactionStatuses.STATEMENT_INTEREST_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.tramsactions.table.title.statement.reminder.fee",
        messageId: TransactionStatuses.STATEMENT_REMINDER_FEE_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.statement.rounding",
        messageId: TransactionStatuses.STATEMENT_ROUNDING_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.statement.startup.fee",
        messageId: TransactionStatuses.STATEMENT_STARTUP_FEE_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.statement.tranche.startup.fee",
        messageId: TransactionStatuses.STATEMENT_TRANCHE_STARTUP_FEE_TRANSACTION,
    },
    {
        contentfulKey: "page.loan.transactions.table.title.statement.direct.debt.fee",
        messageId: TransactionStatuses.STATEMENT_DIRECT_DEBIT_FEE_TRANSACTION,
    },
];

const generatedMessages: GeneratedMessages = generateMessageObject(transactionStatusesdata);

const statementsStatusesData: StatementsMessageData[] = [
    {
        contentfulKey: "page.loan.statements.table.status.collection",
        messageId: StatemnetStatuses.COLLECTION,
    },
    {
        contentfulKey: "page.loan.statements.table.status.curing",
        messageId: StatemnetStatuses.CURING,
    },
    {
        contentfulKey: "page.loan.statements.table.status.overdue",
        messageId: StatemnetStatuses.OVERDUE,
    },
    {
        contentfulKey: "page.loan.statements.table.status.paidinfull",
        messageId: StatemnetStatuses.PAIDINFULL,
    },
    {
        contentfulKey: "page.loan.statements.table.status.partiallypaid",
        messageId: StatemnetStatuses.PARTIALLYPAID,
    },
    {
        contentfulKey: "page.loan.statements.table.status.pending",
        messageId: StatemnetStatuses.PENDING,
    },
    {
        contentfulKey: "page.loan.statements.table.status.unpaid",
        messageId: StatemnetStatuses.UNPAID,
    },
];

const statementsGeneratedMessages: StatementsGeneratedMessages =
    generateStatementsMessageObject(statementsStatusesData);

export const messages = defineMessages({
    pageTitle: {
        id: "page.loan.title",
    },
    pageLoanSubTitle: {
        id: "page.loan.subtitle",
    },
    pageLoanInfoTodayDate: {
        id: "page.loan.info.todaydate",
    },
    pageLoanInfo: {
        id: "page.loan.info",
    },
    pageLoanButtonOverview: {
        id: "page.loan.button.overview",
    },
    pageLoanButtonDocuments: {
        id: "page.loan.button.documents",
    },
    pageLoanOverviewModalSubTitle: {
        id: "page.loan.overview.modal.subtitle",
    },
    pageLoanOverviewModalBalance: {
        id: "page.loan.overview.modal.balance",
    },
    pageLoanOverviewModalCreditInfo: {
        id: "page.loan.overview.modal.creditinfo",
    },
    pageLoanOverviewModalName: {
        id: "page.loan.overview.modal.name",
    },
    pageLoanOverviewModalCustomerNumber: {
        id: "page.loan.overview.modal.customernumber",
    },
    pageLoanOverviewModalCustomerId: {
        id: "page.loan.overview.modal.personalid",
    },
    pageLoanOverviewModalIban: {
        id: "page.loan.overview.modal.iban",
    },
    pageLoanOverviewModalCreditTaken: {
        id: "page.loan.overview.modal.credit.taken",
    },
    pageLoanDocumentsModalInfo: {
        id: "page.loan.documents.modal.info",
    },
    pageLoanDocumentsModalList: {
        id: "page.loan.documents.modal.list",
        values: {
            listitem: (value) => (
                <Text variant="body" listItem={true}>
                    {value}
                </Text>
            ),
        },
    },
    pageLoanDocumentsModalButton: {
        id: "page.loan.documents.modal.button",
    },
    pageLoanPayCreditSubtitle: {
        id: "page.loan.pay.credit.subtitle",
    },
    pageLoanPayCreditInfo: {
        id: "page.loan.pay.credit.info",
    },
    pageLoanPayCreditPhone: {
        id: "page.loan.pay.credit.phone",
        values: {
            strong: (value) => <InnerText variant="bold">{value}</InnerText>,
        },
    },
    pageLoanPayCreditEmail: {
        id: "page.loan.pay.credit.email",
        values: {
            email: (value) => (
                <LinkTo variant="basic" href={`mailto: ${value}`}>
                    {value}
                </LinkTo>
            ),
        },
    },
    pageLoanPayCreditOpeningHours: {
        id: "page.loan.pay.credit.opening.hours",
        values: {
            strong: (value) => (
                <InnerText variant="bold" lineHeight={"1.5"}>
                    {value}
                </InnerText>
            ),
            line: (value) => <Text variant={"body"}>{value}</Text>,
        },
    },
    pageLoanTransactionsTableTitle: {
        id: "page.loan.transactions.table.title",
    },
    pageLoanTransactionsTableDate: {
        id: "page.loan.transactions.table.date",
    },
    pageLoanTransactionsTableType: {
        id: "page.loan.transactions.table.type",
    },
    pageLoanTransactionsTableAmount: {
        id: "page.loan.transactions.table.amount",
    },
    pageLoanTransactionsButton: {
        id: "page.loan.transactions.button",
    },
    pageLoanInvoicesTableTitle: {
        id: "page.loan.invoices.table.title",
    },
    pageLoanInvoicesTableInvoiceNumber: {
        id: "page.loan.invoices.invoice.number",
    },
    pageLoanInvoicesTableInvoiceTitle: {
        id: "page.loan.invoices.invoice.title",
    },
    pageLoanInvoicesTableInvoiceStatus: {
        id: "page.loan.invoices.invoice.status",
    },
    pageLoanInvoicesTableInvoiceDuedate: {
        id: "page.loan.invoices.invoice.duedate",
    },
    pageLoanInvoicesTableInvoiceAmount: {
        id: "page.loan.invoices.invoice.amount",
    },
    balanceMin: {
        id: "page.front.balance.min",
        defaultMessage: "Translation missing",
    },
    balanceMax: {
        id: "page.front.balance.max",
        defaultMessage: "Translation missing",
    },
    defaultTransactionTypeMessage: {
        id: "page.loan.transactions.table.default.message",
        defaultMessage: " ",
    },
    defaultStatementTypeMessage: {
        id: "page.loan.statements.table.default.message",
        defaultMessage: " ",
    },
    ...generatedMessages,
    ...statementsGeneratedMessages,
});
