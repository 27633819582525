import { takeEvery, put, take, select } from "redux-saga/effects";
import { AppActionConstants } from "../actions/actions";
import { loginActions } from "@opr-finance/feature-lfp-login";
import { balanceActions } from "@opr-finance/feature-balance";
import { translationActions } from "@opr-finance/feature-contentful";
import { errorActions } from "@opr-finance/feature-error";
import { AppState } from "../types/general";

export function* watchUserPageTrigger() {
    yield takeEvery(AppActionConstants.USER_PAGE_TRIGGER, handleUserPageTrigger);
}

export function* handleUserPageTrigger() {
    try {
        yield put(loginActions.loginCheckStatus());
        yield take(loginActions.loginCompleteSuccess);
        const activeAccount = yield select((state: AppState) => state.activeAccount.account);
        if (!activeAccount) {
            yield put(balanceActions.fetchAccountTrigger());
        } else {
            yield put(balanceActions.getAccountTrigger(activeAccount));
        }
        yield take(balanceActions.fetchAccountSuccess);
        yield put(
            translationActions.fetchTranslationTrigger({
                clientParams: {
                    space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
                    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
                },
                contentParams: {
                    select: "fields",
                    content_type: "translationV2",
                    "fields.application[in]": "Everyday Plus Online Sweden",
                    "fields.language[in]": "sv",
                    limit: 500,
                },
            })
        );
        yield take(translationActions.fetchTranslationSuccess);
    } catch (e) {
        console.log("fetch user page trigger failed");
        yield put(errorActions.errorTrigger({ message: "user page load failed", url: "/error" }));
    }
}
