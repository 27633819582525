import { ActionType, createAction } from "typesafe-actions";
import { TransactionsActionConstants, TransactionsInitializerType } from "../types/transactions";
import * as VP from "@opr-finance/api-definitions";

export const transactionsActions = {
    fetchTransactionsInitializer: createAction(
        TransactionsActionConstants.FETCH_TRANSACTIONS_INITIALIZER
    )<TransactionsInitializerType>(),
    fetchTransactionsTrigger: createAction(
        TransactionsActionConstants.FETCH_TRANSACTIONS_TRIGGER
    )(),
    fetchTransactionsSuccess: createAction(TransactionsActionConstants.FETCH_TRANSACTIONS_SUCCESS)<
        Array<VP.components["schemas"]["Transaction"]>
    >(),
    fetchTransactionsError: createAction(TransactionsActionConstants.FETCH_TRANSACTIONS_ERROR)(),
};

export type TransactionsAction = ActionType<typeof transactionsActions>;
