import { call, put, select, takeEvery } from "redux-saga/effects";

import * as VP from "@opr-finance/api-definitions";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { smeTransactionsActions } from "../actions/smeTransactions";
import * as transactionsApi from "../api/smeTransactions";
import { formatSmeTransactions } from "../payload";
import {
    E_SmeTransactionsActionConstants,
    T_SmeFeatureTransactionsState,
    T_SmeTransactionsInitializerType,
} from "../types";
import { ActionType } from "typesafe-actions";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* handleSmeFetchTransactions(
    action: ActionType<typeof smeTransactionsActions.smeFetchTransactionsTrigger>
) {
    try {
        const {
            token,
            mockApiCalls,
            gwUrl,
            shownInStatement,
            size,
            excludeTransactionTypes,
            accountId,
            country,
        } = (yield select(
            (state: T_SmeFeatureTransactionsState) => state.transactions.config
        )) as T_SmeTransactionsInitializerType;

        if (!token) {
            logger.log("got no token, back to front page");
            return (window.location.href = "/");
        }

        // const { startDate, endDate } = action.payload;
        const formattedStartDate =
            action.payload?.startDate ?? new Date("2000-01-01").toISOString().split("T")[0];
        const formattedEndDate = action.payload?.endDate ?? new Date().toISOString().split("T")[0];

        const transactions: VP.components["schemas"]["AccountTransactionListResponseV8"] =
            yield call(transactionsApi.fetchSmeTransactions, {
                token,
                gwUrl,
                accountId,
                mockApiCalls,
                shownInStatement,
                size,
                excludeTransactionTypes,
                startDate: formattedStartDate.toString(),
                endDate: formattedEndDate.toString(),
            });

        let pageCount: number = 0;
        let transactionsPayload: Array<VP.components["schemas"]["Transaction"]> = [];

        if (typeof transactions !== "undefined") {
            if (typeof transactions.total !== "undefined")
                pageCount = Math.ceil(transactions.total / size);

            transactionsPayload =
                (transactions?._embedded?.data as Array<VP.components["schemas"]["Transaction"]>) ||
                [];
        }

        if (pageCount > 1) {
            for (let page = 2; page <= pageCount; page++) {
                const newPage: VP.components["schemas"]["AccountTransactionListResponseV8"] =
                    yield call(transactionsApi.fetchSmeTransactions, {
                        token,
                        gwUrl,
                        accountId,
                        mockApiCalls,
                        shownInStatement,
                        size,
                        page: page,
                        excludeTransactionTypes,
                    });

                transactionsPayload = [
                    ...transactionsPayload,
                    ...(newPage?._embedded?.data as Array<VP.components["schemas"]["Transaction"]>),
                ];
            }
        }

        const payload = formatSmeTransactions(transactionsPayload, country);

        yield put(smeTransactionsActions.smeFetchTransactionsSuccess(payload));
    } catch (e) {
        logger.log("fetch transactions trigger failed", e);
    }
}

export function* watchSmeFetchTransactions() {
    yield takeEvery(
        E_SmeTransactionsActionConstants.FETCH_TRANSACTIONS_TRIGGER,
        handleSmeFetchTransactions
    );
}
