import { combineReducers } from "redux";
import {
    loginReducer,
    customerReducer,
    activeAccountReducer,
} from "@opr-finance/feature-lfp-login";
import { balanceReducer } from "@opr-finance/feature-balance";
import { recentNewsReducer, translationReducer } from "@opr-finance/feature-contentful";
import { initializerReducer } from "@opr-finance/feature-initializer";
import { AppState } from "./types/general";
import { statementsReducer } from "@opr-finance/feature-statements";
import { documentReducer } from "@opr-finance/feature-document";
import { transactionsReducer } from "@opr-finance/feature-transactions";
import { withdrawReducer } from "@opr-finance/feature-withdraw";
import { loaderReducer } from "@opr-finance/feature-loader";

export const rootReducer = combineReducers<AppState>({
    login: loginReducer,
    activeAccount: activeAccountReducer,
    customer: customerReducer,
    balance: balanceReducer,
    news: recentNewsReducer,
    statements: statementsReducer,
    transactions: transactionsReducer,
    initializer: initializerReducer,
    document: documentReducer,
    translation: translationReducer,
    withdraw: withdrawReducer,
    loader: loaderReducer,
});
