import React, { FunctionComponent } from "react";
import { useRouteMatch } from "react-router";
import { StyledNavigationLink } from "./NavigationLink.styled";
import { NavigationLinkProps } from "./types";

export const NavigationLink: FunctionComponent<NavigationLinkProps> = (props) => {
    const active =
        useRouteMatch({
            path: props.to,
        }) && props.active
            ? true
            : false;

    return <StyledNavigationLink active={active} {...props} />;
};
