import { defineMessages } from "react-intl";

export const messages = defineMessages({
    expiredPageTitle: {
        id: "page.expired.title",
    },
    expiredPageInfo: {
        id: "page.expired.info",
    },
    expiredPageMessage: {
        id: "page.expired.message",
    },
});
