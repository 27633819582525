import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { SmeTransactionsAction, smeTransactionsActions } from "../actions/smeTransactions";
import { T_SmeTransactionReducerState } from "../types/smeTransactions";

export const initialState: T_SmeTransactionReducerState = {
    transactions: [],
    formatted: [],
    config: {
        mockApiCalls: false,
        gwUrl: "",
        token: "",
        size: 0,
        shownInStatement: false,
        accountId: undefined,
        country: "",
    },
};

export const smeTransactionsReducer = createReducer<
    T_SmeTransactionReducerState,
    SmeTransactionsAction
>(initialState)
    .handleAction(smeTransactionsActions.smeFetchTransactionsInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config = action.payload;
        });
    })
    .handleAction(smeTransactionsActions.smeFetchTransactionsSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.transactions = action.payload.transactions;
            draftState.formatted = action.payload.formatted;
        });
    });
