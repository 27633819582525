import styled from "styled-components";
import {themeGet} from "@styled-system/theme-get";
import {AppThemeProps} from "@opr-finance/themes";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${themeGet('mobileNavigation.background')};
    padding: 8px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
`;
