import React from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Authenticator } from "@opr-finance/feature-lfp-login";
import { FlexColumn, GridContainer, Grid, GridContent } from "@opr-finance/component-grid";
import { BoxContainer } from "@opr-finance/component-box-container";
import { PageTitle } from "@opr-finance/component-content";
import { Text } from "@opr-finance/component-fonts";
import { Button } from "@opr-finance/component-button";
import { Wrapper } from "@opr-finance/component-wrapper";
import {
    documentActions,
    DocumentType,
    DocumentDownloadStatus,
} from "@opr-finance/feature-document";
import { Icon } from "@opr-finance/component-icon";
import { AppState } from "../../types/general";
import { messages } from "./messages";
import { GiosgData } from "../../components/GiosgData/GiosgData";

export function UserPage() {
    const dispatch = useDispatch();
    const { user, disbursementAccount } = useSelector((state: AppState) => {
        return {
            user: state.customer.user,
            disbursementAccount: state.balance.disbursementAccount,
        };
    });

    const {
        config: { language },
        document,
    } = useSelector((state: AppState) => state.document);

    const { formatMessage: fm } = useIntl();

    const loading: boolean =
        document && document.poa.downloadStatus === DocumentDownloadStatus.IN_PROGRESS
            ? true
            : false;

    if (!user || user === undefined) {
        return <Redirect to="/" />;
    }

    return (
        <Authenticator>
            <FlexColumn>
                <PageTitle title={fm(messages.pageUserTitle)} />
                <GridContainer>
                    <Grid width={"100%"}>
                        <GridContent>
                            <BoxContainer>
                                <Text variant={"bodyTitle"}>
                                    {fm(messages.pageUserPersonalSubtitle)}
                                </Text>
                                <GridContainer>
                                    <Grid width={1 / 2}>
                                        <FlexColumn>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserPersonalName)}
                                            </Text>
                                            <Text variant={"userPageBody"}>
                                                {`${user?.customerBasicInfo?.givenName} ${user?.customerBasicInfo?.surName}`}
                                            </Text>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserPersonalCustomernumber)}
                                            </Text>
                                            <Text variant={"userPageBody"}>
                                                {user?.customerBasicInfo?.customerId}
                                            </Text>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserPersonalId)}
                                            </Text>
                                            <Text variant={"userPageBody"} paddingBottom={"28px"}>
                                                {user?.customerBasicInfo?.ssn}
                                            </Text>
                                        </FlexColumn>
                                    </Grid>
                                    <Grid width={1 / 2}>
                                        <FlexColumn>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserPersonalIban)}
                                            </Text>
                                            <Text variant={"userPageBody"}>
                                                {disbursementAccount?.number}
                                            </Text>
                                        </FlexColumn>
                                    </Grid>
                                </GridContainer>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GridContainer>
                    <Grid width={"100%"}>
                        <GridContent>
                            <BoxContainer>
                                <Text variant={"bodyTitle"}>
                                    {fm(messages.pageUserContactSubtitle)}
                                </Text>
                                <GridContainer>
                                    <Grid width={1 / 2}>
                                        <FlexColumn>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserContactAddress)}
                                            </Text>
                                            <Text variant={"userPageBody"}>
                                                {user?.address?.streetAddress}
                                            </Text>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserContactPost)}
                                            </Text>
                                            <Text variant={"userPageBody"}>
                                                {user?.address?.zipCode}
                                            </Text>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserContactCity)}
                                            </Text>
                                            <Text variant={"userPageBody"}>
                                                {user?.address?.city}
                                            </Text>
                                            <Text
                                                variant={"userPageBody"}
                                                paddingTop={"8px"}
                                                paddingBottom={"28px"}>
                                                {fm(messages.pageUserContactInfo)}
                                            </Text>
                                        </FlexColumn>
                                    </Grid>
                                    <Grid width={1 / 2}>
                                        <FlexColumn>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserContactPhone)}
                                            </Text>
                                            <Text variant={"userPageBody"}>
                                                {`${user?.customerBasicInfo?.mobilePhoneCode} ${user?.customerBasicInfo?.mobilePhoneNumber}`}
                                            </Text>
                                            <Text variant={"userPageTitle"}>
                                                {fm(messages.pageUserContactEmail)}
                                            </Text>
                                            <Text variant={"userPageBody"}>
                                                {user?.customerBasicInfo?.email}
                                            </Text>
                                        </FlexColumn>
                                    </Grid>
                                </GridContainer>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GridContainer>
                    <Grid width={"100%"}>
                        <GridContent>
                            <BoxContainer>
                                <Text variant={"bodyTitle"}>
                                    {fm(messages.pageUserGuardianTitle)}
                                </Text>
                                <GridContainer>
                                    <Grid width={1 / 2}>
                                        <Wrapper variant="basic" flexDirection="column">
                                            <Text variant={"userPageBody"}>
                                                {fm(messages.pageUserGuardianUserManual)}
                                            </Text>
                                            <Button
                                                variant="secondary"
                                                marginBottom="24px"
                                                marginLeft="24px"
                                                marginRight="auto"
                                                disabled={loading}
                                                onClick={() => {
                                                    dispatch(
                                                        documentActions.fetchDocumentTrigger({
                                                            documentType: DocumentType.POA,
                                                            documentId: "poa",
                                                            data: {
                                                                name: `${user?.customerBasicInfo?.givenName} ${user?.customerBasicInfo?.surName}`,
                                                                ssn:
                                                                    user?.customerBasicInfo?.ssn ||
                                                                    "",
                                                                address:
                                                                    user?.address?.streetAddress ||
                                                                    "",
                                                                postCode:
                                                                    user?.address?.zipCode || "",
                                                                city: user?.address?.city || "",
                                                                phoneNumber:
                                                                    `${user?.customerBasicInfo?.phoneNumber}` ||
                                                                    "",
                                                                params: {
                                                                    translationType: "",
                                                                    language,
                                                                },
                                                            },
                                                        })
                                                    );
                                                }}>
                                                {loading && (
                                                    <Icon icon={["fas", "circle-notch"]} spin />
                                                )}
                                                {fm(messages.pageUserGuardianButton)}
                                            </Button>
                                        </Wrapper>
                                    </Grid>
                                    <Grid width={1 / 2} flexDirection="column">
                                        {fm(
                                            messages.pageUserGuardianReturnAddress,
                                            messages.pageUserGuardianReturnAddress.values
                                        )}
                                    </Grid>
                                </GridContainer>
                            </BoxContainer>
                        </GridContent>
                    </Grid>
                </GridContainer>
                <GiosgData
                    customerName={`${user?.customerBasicInfo?.givenName} ${user?.customerBasicInfo?.surName}`}
                    customerId={user?.customerBasicInfo?.customerId}
                />
            </FlexColumn>
        </Authenticator>
    );
}
