import { call, put, select, takeEvery } from "redux-saga/effects";

import * as VP from "@opr-finance/api-definitions";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { transactionsActions } from "../actions/transactions";
import * as transactionsApi from "../api/transactions";
import {
    TransactionsActionConstants,
    FeatureTransactionsState,
    TransactionsInitializerType,
    T_VpTransactionsResponse,
} from "../types/transactions";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* handleFetchTransactions() {
    try {
        const {
            token,
            mockApiCalls,
            gwUrl,
            shownInStatement,
            countPerPage,
            excludeTransactionTypes,
            accountId,
        } = (yield select(
            (state: FeatureTransactionsState) => state.transactions.config
        )) as TransactionsInitializerType;

        if (!token) {
            logger.log("got no token, back to front page");
            // return (window.location.href = "/");
        }

        logger.log("got token, fetch transactions");

        const transactions: T_VpTransactionsResponse = yield call(
            transactionsApi.fetchTransactions,
            {
                token,
                gwUrl,
                accountId,
                mockApiCalls,
                shownInStatement,
                countPerPage,
                excludeTransactionTypes,
            }
        );

        let pageCount: number = 0;
        let transactionsPayload: Array<VP.components["schemas"]["Transaction"]> = [];

        if (typeof transactions !== "undefined") {
            if (typeof transactions.total !== "undefined")
                pageCount = Math.ceil(transactions.total / countPerPage);

            transactionsPayload = transactions?._embedded?.transactions as Array<
                VP.components["schemas"]["Transaction"]
            >;
        }

        if (pageCount > 1) {
            for (let page = 2; page <= pageCount; page++) {
                const newPage: T_VpTransactionsResponse = yield call(
                    transactionsApi.fetchTransactions,
                    {
                        token,
                        gwUrl,
                        accountId,
                        mockApiCalls,
                        shownInStatement,
                        countPerPage,
                        currentPage: page,
                        excludeTransactionTypes,
                    }
                );
                transactionsPayload = [
                    ...transactionsPayload,
                    ...(newPage?._embedded?.transactions as Array<
                        VP.components["schemas"]["Transaction"]
                    >),
                ];
            }
        }

        logger.log("got transactions", transactionsPayload);

        yield put(transactionsActions.fetchTransactionsSuccess(transactionsPayload));
    } catch (e) {
        logger.log("fetch transactions trigger failed", e);
    }
}

export function* watchFetchTransactions() {
    yield takeEvery(
        TransactionsActionConstants.FETCH_TRANSACTIONS_TRIGGER,
        handleFetchTransactions
    );
}
