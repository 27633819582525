import React, { FunctionComponent } from "react";
import { Wrapper } from "@opr-finance/component-wrapper";
import { Logo } from "@opr-finance/component-logo";
import { Button } from "@opr-finance/component-button";
import { Navigation } from "./Navigation";
import { HeaderProps } from "./types";
import styled, { createGlobalStyle } from "styled-components";

const EmptyMobileWrapper = styled.div`
    display: none;
    @media (max-width: 786px) {
        display: flex;
        width: 100%;
        height: 46px;
    }
`;

export const Header: FunctionComponent<HeaderProps> = (props) => {
    return (
        <>
            <Wrapper variant="header">
                <Wrapper variant="headerContent">
                    <Wrapper variant="left" marginTop={[0, "19px", 0]}>
                        {props.logo}
                    </Wrapper>
                    {props.authenticated && (
                        <Wrapper variant="headerNavigation">
                            <Navigation navigationItems={props.navigationItems} />
                        </Wrapper>
                    )}
                    <Wrapper variant="right" padding={"17px 0"} minWidth={"102px"}>
                        {props.button}
                    </Wrapper>
                </Wrapper>
                {props.authenticated && (
                    <Wrapper variant="tabletNavigation">
                        <Navigation navigationItems={props.navigationItems} />
                    </Wrapper>
                )}
            </Wrapper>
            <EmptyMobileWrapper />
        </>
    );
};
