import React from "react";
import { useIntl } from "react-intl";
import { FlexColumn, GridContainer, Grid, GridContent } from "@opr-finance/component-grid";
import { BoxContainer } from "@opr-finance/component-box-container";
import { PageTitle } from "@opr-finance/component-content";
import { Text } from "@opr-finance/component-fonts";
import { Wrapper } from "@opr-finance/component-wrapper";
import { messages } from "./messages";

export function NoLoanPage() {
    const { formatMessage: fm } = useIntl();

    return (
        <FlexColumn>
            <PageTitle title={fm(messages.pageTitle)} />
            <GridContainer>
                <Grid width={"100%"}>
                    <GridContent>
                        <BoxContainer>
                            <Wrapper variant="centered" mb={"30px"}>
                                <Text variant={"bodyTitle"} fontSize="16px" lineHeight="1.5">
                                    {fm(messages.errorMessage, messages.errorMessage.values)}
                                </Text>
                            </Wrapper>
                        </BoxContainer>
                    </GridContent>
                </Grid>
            </GridContainer>
        </FlexColumn>
    );
}
