import * as VP from "@opr-finance/api-definitions";
import { DefaultInitializerType } from "@opr-finance/utils";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

type T_tmpVpTransactionsRequestParameters =
    VP.operations["80d12360-863a-4fc8-9a59-370576d2845f"]["parameters"]["query"];

export type T_FetchSmeTransactionsRequest = {
    accountId: VP.components["parameters"]["accountId"] | undefined;
} & T_tmpVpTransactionsRequestParameters &
    DefaultInitializerType;

type T_HeadersWithMock = HeadersInit & {
    "X-transactions-type"?: "no-transactions" | "normal";
};

function addParameter(url: string, key: string, value: string | number) {
    const separator = url.indexOf("?") !== -1 ? "&" : "?";
    return `${url}${separator}${key}=${value}`;
}

export async function fetchSmeTransactions(
    data: T_FetchSmeTransactionsRequest
): Promise<VP.components["schemas"]["AccountTransactionListResponseV8"]> {
    let url = `${data.gwUrl}/api/loan/v8/accounts/${data.accountId}/transactions`;
    const method = "GET";

    url = data.startDate ? addParameter(url, "startDate", data.startDate) : url;
    url = data.endDate ? addParameter(url, "endDate", data.endDate) : url;
    url =
        data.shownInStatement !== null && data.shownInStatement !== undefined
            ? addParameter(url, "shownInStatement", String(data.shownInStatement))
            : url;
    url = data.excludeTransactionTypes
        ? addParameter(url, "excludeTransactionTypes", data.excludeTransactionTypes)
        : url;

    url = data.size ? addParameter(url, "size", data.size) : url;
    url = data.page ? addParameter(url, "page", data.page) : url;

    const headers: T_HeadersWithMock = {
        "content-type": "application/json",
        authorization: data.token as string,
        ...(data.mockApiCalls && { "X-transactions-type": "normal" }),
    };

    const result: Response = await fetch(url, {
        method,
        headers,
    });
    const response = await result.json();

    if (data.mockApiCalls) {
        logger.log("MOCK DATA - COMPANY TRANSACTIONS DATA");
        logger.log(response);
    }

    return response;
}
