import { format, parseISO } from "date-fns";
import * as VP from "@opr-finance/api-definitions";
import { currency, CurrencyFormat, CurrencyLocale } from "@opr-finance/component-currency";

import {
    E_SmeTransactionsItemConf,
    I_SmeFormattedTransactions,
    T_SmeTransactionsSuccessAction,
} from "../types";

const interestTypes = [
    "LoanAdjustmentInterestTransaction",
    "StatementInterestBeforeFirstStatementTransaction",
    "StatementInterestTransaction",
];

export const formatSmeTransactions = (
    transactions: Array<VP.components["schemas"]["Transaction"]>,
    currencyCountry: string
): T_SmeTransactionsSuccessAction => {
    const formatAmount = (amount: number) => {
        return currency({
            value: amount,
            locale: CurrencyLocale[currencyCountry],
            currency: CurrencyFormat[currencyCountry],
        });
    };

    // Function to extract an actual code for merging interest rows (or just saving row)
    const summarizeInterest = (
        interestTypes: string[],
        mapTransactionValue: Array<I_SmeFormattedTransactions>
    ): Array<I_SmeFormattedTransactions> => {
        if (mapTransactionValue.length === 1) return mapTransactionValue;
        let newRow: Array<I_SmeFormattedTransactions> = [];
        let countedRow: I_SmeFormattedTransactions;

        mapTransactionValue.forEach((item, index) => {
            const found = interestTypes.find(
                (interestType) => interestType === item.transactionType
            );
            if (!found) {
                newRow.push(item);
            } else {
                if (!countedRow) {
                    countedRow = item;
                } else {
                    if (countedRow.amount != undefined) {
                        if (item.amount != undefined) {
                            countedRow.amount += item.amount;
                            countedRow.formattedAmount = formatAmount(countedRow.amount);
                        }
                    } else if (item.amount != undefined) {
                        countedRow.amount = item.amount;
                        countedRow.formattedAmount = formatAmount(countedRow.amount);
                    }
                }
            }
            if (index === mapTransactionValue.length - 1 && countedRow !== undefined) {
                countedRow.transactionType = "summarizedInterest";
                newRow.push(countedRow);
            }
        });
        return newRow;
    };

    const formatted: Array<I_SmeFormattedTransactions> = transactions.map(
        ({ transactionDate, transactionType, transactionMsg, amount }) => {
            const formattedDate =
                typeof transactionDate !== "undefined"
                    ? format(
                          parseISO(transactionDate),
                          E_SmeTransactionsItemConf.DEFAULT_FORMAT_DATE
                      )
                    : "";
            const formattedTitle = transactionMsg ? transactionMsg : "null";
            const formattedAmount = formatAmount(Number(amount));
            return {
                transactionType,
                transactionDate,
                formattedDate,
                formattedAmount,
                amount,
                formattedTitle,
            };
        }
    );

    let mapTransactions = new Map<string, Array<I_SmeFormattedTransactions>>();

    formatted.forEach((item) => {
        if (!mapTransactions.has(item.formattedDate!)) {
            mapTransactions.set(item.formattedDate!, [item]);
        } else {
            const data = mapTransactions.get(item.formattedDate!) || [];
            mapTransactions.set(item.formattedDate!, [...data, item]);
        }
    });

    let mappedTransactions = new Map<string, Array<I_SmeFormattedTransactions>>();
    for (let entry of mapTransactions.entries()) {
        const summarizedData = summarizeInterest(interestTypes, entry[1]);
        mappedTransactions.set(entry[0], summarizedData);
    }
    const arrayOfTransactions = Array.from(mappedTransactions.values());
    const flatArray = arrayOfTransactions.flat();

    return {
        formatted: flatArray,
        transactions,
    };
};
