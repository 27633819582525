import { SystemStyleObject } from "@styled-system/css";

export function footerTitle(): SystemStyleObject {
    return {
        fontFamily: "open sans",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#2bace2",
        marginBottom: "36px",
    };
}

export function footerLink(): SystemStyleObject {
    return {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontFamily: "open sans",
        color: "#0c445c",
        textDecoration: "none",
        padding: "0 0 24px 0",
        height: "24px",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    };
}

export function footerText(): SystemStyleObject {
    return {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontFamily: "open sans",
        color: "#0c445c",
        textDecoration: "none",
        padding: "0 0 40px 0",
        height: "24px",
    };
}

export function flexLayoutStyles() {
    return {
        footerTitle: {
            fontFamily: "open sans",
            fontWeight: "bold",
            fontSize: "16px",
            color: "#2bace2",
            marginBottom: "36px",
        },
        footerLink: {
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontFamily: "open sans",
            color: "#0c445c",
            textDecoration: "none",
            padding: "0 0 24px 0",
            height: "24px",
            cursor: "pointer",
            "&:hover": {
                textDecoration: "underline",
            },
        },
        footerText: {
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontFamily: "open sans",
            color: "#0c445c",
            textDecoration: "none",
            padding: "0 0 40px 0",
            height: "24px",
        },
        footerContact: {
            fontSize: "14px",
            fontFamily: "open sans",
            color: "#0c445c",
            textDecoration: "none",
            padding: "-16px 0 0 0",
        },
    };
}

export function flexPipelinePageStyles() {
    return {
        body: {
            fontFamily: "open sans",
            fontSize: "16px",
            fontWeight: "400",
            color: "#0c445c",
            padding: "8px 0 0 0",
        },
        bodyTitle: {
            fontFamily: "open sans",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#0c445c",
            padding: "32px 0 8px 0",
        },
        pageTitle: {
            fontFamily: "open sans",
            fontSize: "32px",
            fontWeight: "bold",
            color: "#0c445c",
            padding: "8px 0 0 0",
        },
        textField: {
            border: "solid 1px #a9d3e5",
            borderRadius: "5px",
            boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
            backgroundColor: "#fff",
            color: "#0c445c",
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: "1.5",
            width: "100%",
            height: "30px",
            padding: "8px 8px 8px 8px",
            margin: "8px 0 0 0",
        },
        select: {
            border: "solid 1px #a9d3e5",
            borderRadius: "5px",
            boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
            backgroundColor: "#fff",
            color: "#0c445c",
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "1.5",
            width: "100%",
            height: "30px",
            padding: "0 8px 0 8px",
            margin: "8px 0 0 0",
        },
        checkbox: {
            border: "none",
            borderRadius: "5px",
            boxShadow: "0 1px 2px 0 rgba(12, 68, 92, 0.5)",
            backgroundColor: "#fff",
            color: "#0c445c",
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "1.5",
            width: "auto",
            height: "30px",
            padding: "0 8px 0 8px",
            margin: "0 0 0 0",
        },
        checkboxText: {
            fontFamily: "open sans",
            fontSize: "16px",
            fontWeight: "400",
            color: "#0c445c",
            padding: "0 0 0 8px",
        },
        button: {
            border: "0px",
            borderRadius: "5px",
            backgroundColor: "transparent",
            backgroundImage: "linear-gradient(to top, #59b268, #afe681)",
            height: "34px",
            "&:hover": {
                backgroundImage:
                    "linear-gradient(to top, rgba(89, 178, 104, 0.7), rgba(175, 230, 129, 0.7));",
            },
        },
        buttonText: {
            fontFamily: "open sans",
            fontWeight: "bold",
            fontSize: "16px",
            color: "#fff",
            textTransform: "uppercase",
        },
        formError: {
            fontFamily: "open sans",
            fontWeight: "bold",
            fontSize: "14px",
            color: "darkred",
            padding: "8px 0 0 0",
        },
    };
}

export function flexCompletedPageStyles() {
    return {
        body: {
            fontFamily: "open sans",
            fontSize: "16px",
            fontWeight: "400",
            color: "#0c445c",
            padding: "8px 0 4px 0",
        },
        pageTitle: {
            fontFamily: "open sans",
            fontSize: "32px",
            fontWeight: "bold",
            color: "#0c445c",
            padding: "8px 0 0 0",
        },
    };
}
