import React, { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { BoxContainer } from "@opr-finance/component-box-container";
import { Button } from "@opr-finance/component-button";
import { FlexColumn, Grid, GridContainer, GridContent } from "@opr-finance/component-grid";
import { Wrapper } from "@opr-finance/component-wrapper";
import { Text } from "@opr-finance/component-fonts";

import { messages } from "../Frontpage/messages";
import { ModalDialog } from "@opr-finance/component-modal-dialog";
import { SectionBox } from "@opr-finance/component-content";
import { useDispatch, useSelector } from "react-redux";
import { documentActions, DocumentType } from "@opr-finance/feature-document";
import { AppState } from "../../types/general";

const Row = styled.div`
    display: flex;
    width: 100%;
`;

export function CollectionBlock() {
    const { formatMessage: fm } = useIntl();
    const dispatch = useDispatch();
    const promissoryNoteId = useSelector((state: AppState) => state.document.documentId);
    const [isLoanDocumentModalOpen, setIsLoanDocumentModalOpen] = useState(false);

    const getPdfClick = (event, documentId, documentType) => {
        event.preventDefault();

        dispatch(
            documentActions.fetchDocumentTrigger({
                documentId,
                documentType,
            })
        );
    };

    return (
        <FlexColumn>
            <GridContainer flex={1}>
                <Grid>
                    <GridContent>
                        <BoxContainer
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <Text variant={"bodyTitle"} paddingBottom="24px">
                                {fm(messages.pageCollectionTitle)}
                            </Text>
                            <Text variant={"body"} padding="24px">
                                {fm(messages.pageCollectionInfo)}
                            </Text>
                            <Row>
                                <Text variant={"body"} paddingLeft="24px">
                                    {fm(messages.collectionPagePhoneText)}
                                    :&nbsp;
                                </Text>
                                <Text variant={"body"}>
                                    {fm(messages.collectionPagePhoneNumber)}
                                </Text>
                            </Row>
                            <Row>
                                <Text variant={"body"} paddingLeft="24px">
                                    {fm(messages.collectionPageEmailText)}
                                    :&nbsp;
                                </Text>

                                <Text variant={"body"} paddingBottom="24px">
                                    {fm(
                                        messages.collectionPageEmailLink,
                                        messages.collectionPageEmailLink.values
                                    )}
                                </Text>
                            </Row>
                        </BoxContainer>
                    </GridContent>
                </Grid>
            </GridContainer>
            <GridContainer flex={1}>
                <Grid>
                    <GridContent>
                        <BoxContainer
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <Text variant={"bodyTitle"} paddingBottom="24px">
                                {fm(messages.pageCollectionCreditInfoTitle)}
                            </Text>
                            <Wrapper variant="centered" flexDirection={"column"}>
                                <Button
                                    variant="secondary"
                                    width={"100%"}
                                    marginBottom="52px"
                                    onClick={() => {
                                        setIsLoanDocumentModalOpen(true);
                                    }}
                                >
                                    {fm(messages.pageLoanButtonDocuments)}
                                </Button>
                            </Wrapper>
                        </BoxContainer>
                    </GridContent>
                </Grid>
            </GridContainer>

            <ModalDialog
                modalTitle={fm(messages.pageLoanButtonDocuments)}
                isOpen={isLoanDocumentModalOpen}
                onClick={() => {
                    setIsLoanDocumentModalOpen(false);
                }}
                dialogVariant="default"
            >
                <Text variant="body">{fm(messages.pageLoanDocumentsModalInfo)}</Text>
                <SectionBox flexDirection={"column"} marginTop={"8px"}>
                    {fm(
                        messages.pageLoanDocumentsModalList,
                        messages.pageLoanDocumentsModalList.values
                    )}
                </SectionBox>
                <Wrapper variant="centered">
                    <Button
                        variant="secondary"
                        onClick={(event) =>
                            getPdfClick(event, promissoryNoteId, DocumentType.PROMISSORY_NOTE)
                        }
                        marginTop={"24px"}
                        alignSelf={"center"}
                    >
                        {fm(messages.pageLoanDocumentsModalButton)}
                    </Button>
                </Wrapper>
            </ModalDialog>
        </FlexColumn>
    );
}
