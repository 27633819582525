import { FunctionComponent, ReactNode } from "react";
import styled, { createGlobalStyle } from "styled-components";
import {
    space,
    layout,
    flexbox,
    position,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
} from "styled-system";
import { AppThemeProps } from "@opr-finance/themes";

export type BaseLayoutProps = SpaceProps &
    LayoutProps &
    FlexboxProps &
    PositionProps & { children: ReactNode };
export type BaseLayoutPropsWithTheme = BaseLayoutProps & AppThemeProps;
export type GlobalStylesProps = {
    bodyBackgroundImage?: string;
};

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
    html {
        box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    html,
    body,
    #root {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    body {
        background-image: url(${(props: GlobalStylesProps) => props.bodyBackgroundImage});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;        
    }
    textarea,
    select,
    input,
    button {
        outline: 0;
    }
`;

export const BaseLayout: FunctionComponent<BaseLayoutProps> = styled.div<BaseLayoutProps>`
    ${space}
    ${layout}
    ${flexbox}
    ${position}    
    margin-bottom: 56px;
`;
