import React, { ReactNode } from "react";
import { Grid, GridContainer, GridContent, FullGridContainer } from "@opr-finance/component-grid";
import { BoxContainer } from "@opr-finance/component-box-container";
import { BaseLayout, GlobalStyles, GlobalStylesProps } from "./Layout.styled";

export type LayoutProps = {
    header: ReactNode;
    footer: ReactNode;
    children: ReactNode;
} & GlobalStylesProps;

export function Layout(props: LayoutProps) {
    return (
        <BaseLayout>
            <GlobalStyles bodyBackgroundImage={props.bodyBackgroundImage} />
            <FullGridContainer marginBottom={"32px"}>{props.header}</FullGridContainer>
            <GridContainer>{props.children}</GridContainer>
            <FullGridContainer>{props.footer}</FullGridContainer>
        </BaseLayout>
    );
}
