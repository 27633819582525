import React from "react";
import { useIntl } from "react-intl";
import { FlexColumn, GridContainer, Grid, GridContent } from "@opr-finance/component-grid";
import { BoxContainer } from "@opr-finance/component-box-container";
import { PageTitle } from "@opr-finance/component-content";
import { Text } from "@opr-finance/component-fonts";
import { messages } from "./messages";

export function ExpiredPage() {
    const { formatMessage: fm } = useIntl();

    return (
        <FlexColumn>
            <PageTitle title={fm(messages.expiredPageTitle)} isTitleCentered={true} />
            <GridContainer>
                <Grid width={"100%"}>
                    <GridContent>
                        <BoxContainer>
                            <Text variant={"bodyTitle"} lineHeight="1.5" paddingBottom="17px">
                                {fm(messages.expiredPageInfo)}
                            </Text>
                            <Text
                                variant={"body"}
                                paddingLeft="24px"
                                paddingRight="24px"
                                marginBottom="64px">
                                {fm(messages.expiredPageMessage)}
                            </Text>
                        </BoxContainer>
                    </GridContent>
                </Grid>
            </GridContainer>
        </FlexColumn>
    );
}
