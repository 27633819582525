import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { loginActions } from "@opr-finance/feature-lfp-login";
import { FlexColumn } from "@opr-finance/component-grid";
import { PageTitle } from "@opr-finance/component-content";

import { messages } from "./messages";

export function LogoutPage() {
    const dispatch = useDispatch();
    const { formatMessage: fm } = useIntl();

    useEffect(() => {
        dispatch(loginActions.logout());
    }, [dispatch]);

    console.log("render logout page?");

    return (
        <FlexColumn>
            <PageTitle title={fm(messages.pageTitle)} isTitleCentered={true} />
        </FlexColumn>
    );
}
