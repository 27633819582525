import { takeEvery, take, put, select } from "redux-saga/effects";
import { AppActionConstants } from "../actions/actions";
import { loginActions } from "@opr-finance/feature-lfp-login";
import { balanceActions } from "@opr-finance/feature-balance";
import { recentNewsActions } from "@opr-finance/feature-contentful";
import { statementsActions } from "@opr-finance/feature-statements";
import { errorActions } from "@opr-finance/feature-error";
import { withdrawActions } from "@opr-finance/feature-withdraw";
import { AppState } from "../types/general";

export function* watchFrontPageTrigger() {
    yield takeEvery(AppActionConstants.FRONT_PAGE_TRIGGER, handleFrontPageTrigger);
}

export function* handleFrontPageTrigger() {
    try {
        yield put(loginActions.loginCheckStatus());
        yield take(loginActions.loginCompleteSuccess);
        const activeAccount = yield select((state: AppState) => state.activeAccount.account);

        if (!activeAccount) {
            yield put(balanceActions.fetchAccountTrigger());
        } else {
            yield put(balanceActions.getAccountTrigger(activeAccount));
        }
        yield take(balanceActions.fetchAccountSuccess);
        yield put(
            recentNewsActions.fetchRecentNewsTrigger({
                clientParams: {
                    space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
                    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
                },
                contentParams: {
                    select: "fields,sys.createdAt",
                    content_type: "recentNews",
                    "fields.language[in]": "sv",
                    "fields.application[in]": "Everyday plus Online",
                },
            })
        );
        yield take(recentNewsActions.fetchRecentNewsSuccess);
        yield put(statementsActions.fetchStatementsTrigger());
        yield take(statementsActions.fetchStatementsSuccess);
        yield put(withdrawActions.withdrawReset());
    } catch (e) {
        console.log("fetch front page trigger failed");
        yield put(errorActions.errorTrigger({ message: "frontpage load failed", url: "/error" }));
    }
}
