import { defineMessages } from "react-intl";

export const messages = defineMessages({
    show: {
        id: "table.paginator.show",
        defaultMessage: "Translation missing",
    },
    noData: {
        id: "table.content.nodata",
        defaultMessage: "Translation missing",
    },
});
