import React from "react";
import { defineMessages } from "react-intl";
import { Text } from "@opr-finance/component-fonts";

export const messages = defineMessages({
    pageUserTitle: {
        id: "page.user.title",
    },
    pageUserPersonalSubtitle: {
        id: "page.user.personal.subtitle",
    },
    pageUserPersonalName: {
        id: "page.user.personal.name",
    },
    pageUserPersonalCustomernumber: {
        id: "page.user.personal.customernumber",
    },
    pageUserPersonalId: {
        id: "page.user.personal.id",
    },
    pageUserPersonalIban: {
        id: "page.user.personal.iban",
    },
    pageUserContactSubtitle: {
        id: "page.user.contact.subtitle",
    },
    pageUserContactAddress: {
        id: "page.user.contact.address",
    },
    pageUserContactPost: {
        id: "page.user.contact.post",
    },
    pageUserContactCity: {
        id: "page.user.contact.city",
    },
    pageUserContactPhone: {
        id: "page.user.contact.phone",
    },
    pageUserContactEmail: {
        id: "page.user.contact.email",
    },
    pageUserContactInfo: {
        id: "page.user.contact.info",
    },
    pageUserGuardianTitle: {
        id: "page.user.guardian.title",
    },
    pageUserGuardianInfo: {
        id: "page.user.guardian.info",
    },
    pageUserGuardianUserManual: {
        id: "page.user.guardian.user.manual",
    },
    pageUserGuardianButton: {
        id: "page.user.guardian.button",
    },
    pageUserGuardianReturnAddress: {
        id: "page.user.guardian.return.address",
        values: {
            title: (value) => <Text variant="userPageTitle">{value}</Text>,
            line: (value) => (
                <Text variant="userPageBody" paddingBottom={0}>
                    {value}
                </Text>
            ),
        },
    },
});
