import styled from "styled-components";
import { BalanceContentProps } from "../types/balance";

export const BalanceContainer = styled.div`
    display: flex;
    width: 100%;
    height: 16px;
    margin: 0;
    padding: 0;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #94b93a;
    align-items: center;
    width: 100%;
    max-width: 500px;
`;

export const BalanceContent = styled.div<BalanceContentProps>`
    height: 14px;
    width: ${(props) => props.percentage}%;
    margin: 0;
    padding: 0;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #94b93a;
    border-radius: 8px;
`;

export const BalanceAmountContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 500px;
    margin: 8px 0 0 0;
    align-items: center;
`;

export const BalanceAmountLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
`;

export const BalanceAmountRight = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    text-align: right;
`;
