import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { format, parseISO } from "date-fns";
import { currency, CurrencyLocale, CurrencyFormat } from "@opr-finance/component-currency";
import { TransactionsAction, transactionsActions } from "../actions/transactions";
import { TransactionReducerState, TransactionsItemConf } from "../types/transactions";

export const initialState = {
    transactions: [],
    formatted: [],
    config: {
        mockApiCalls: false,
        gwUrl: "",
        token: "",
        countPerPage: 0,
        shownInStatement: false,
        accountId: undefined,
    },
};

export const transactionsReducer = createReducer<TransactionReducerState, TransactionsAction>(
    initialState
)
    .handleAction(transactionsActions.fetchTransactionsInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config = action.payload;
        });
    })
    .handleAction(transactionsActions.fetchTransactionsSuccess, (state, action) => {
        return produce(state, (draftState) => {
            const transactions = action.payload.map((item) => {
                const formattedDate =
                    typeof item.transactionDate !== "undefined"
                        ? format(
                              parseISO(item.transactionDate),
                              TransactionsItemConf.DEFAULT_FORMAT_DATE
                          )
                        : "";
                const formattedAmount = currency({
                    value: item.amount,
                    locale: CurrencyLocale.Sweden,
                    currency: CurrencyFormat.Sweden,
                });
                const formattedTitle = item.transactionMsg ? item.transactionMsg : "null";

                return {
                    ...item,
                    formattedDate,
                    formattedAmount,
                    formattedTitle,
                };
            });

            draftState.transactions = action.payload;
            draftState.formatted = transactions;
        });
    });
