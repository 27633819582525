import React, { FunctionComponent, ReactNode } from "react";
import { Wrapper } from "@opr-finance/component-wrapper";
import { GridContainer, Grid, GridContent } from "@opr-finance/component-grid";
import { BoxContainer, Box } from "@opr-finance/component-box-container";
import { FooterProps, FooterColumn, FooterComponentProps } from "./types";
import styled from "styled-components";

const Container = styled.div`
    width: 37%;
    &:last-child {
        width: 26%;
        @media (max-width: 786px) {
            width: 100%;
        }
    }
    @media (max-width: 786px) {
        width: 100%;
    }
`;

export const Footer: FunctionComponent<FooterProps> = (props: FooterProps) => {
    const content: ReactNode[] = [];

    const itemContainer = (item: FooterColumn, length: number, key: number = 1) => (
        <Grid
            width={1 / length}
            key={`item${key}`}
            order={item.order ? item.order : 0}
            marginBottom={item.marginBottom && item.marginBottom}>
            <GridContent width="auto">{item.content()}</GridContent>
        </Grid>
    );

    if (props.columns) {
        const columnCount = props.columns.length;
        props.columns.map((column, index) => {
            content.push(itemContainer(column, columnCount, index));
        });
    }

    props.children &&
        !props.columns &&
        content.push(itemContainer({ content: () => props.children }, 1));

    return (
        <Wrapper variant="footer">
            {props.before && <Grid maxWidth={"100%"}>{props.before}</Grid>}
            <GridContainer>{content}</GridContainer>
            {props.after && <Grid maxWidth={"100%"}>{props.after}</Grid>}
        </Wrapper>
    );
};

export const FooterComponent: FunctionComponent<FooterComponentProps> = (
    props: FooterComponentProps
) => {
    const content: ReactNode[] = [];

    const itemContainer = (item: FooterColumn, key: string) => (
        <Container key={key}>{item.content()}</Container>
    );

    if (props.columns) {
        props.columns.map((column, index) => {
            content.push(itemContainer(column, `column-${index}`));
        });
    }

    return (
        <Box styleConfig={{ root: props.styleConfig }}>
            <GridContainer p={["0 0", "0 65px"]}>{content}</GridContainer>
        </Box>
    );
};
