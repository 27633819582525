import { all, fork } from "redux-saga/effects";
import {
    watchCheckLoginStatus,
    watchCompleteLogin,
    watchStartLogin,
    watchFetchCustomerTrigger,
    watchLogout,
    watchAccountSelection,
} from "@opr-finance/feature-lfp-login";
import {
    watchFetchNewsTrigger,
    watchFetchTranslationTrigger,
} from "@opr-finance/feature-contentful";
import { watchFetchStatements } from "@opr-finance/feature-statements";
import { watchFetchAccountTrigger } from "@opr-finance/feature-balance";
import { watchInitializers } from "@opr-finance/feature-initializer";
import { watchFetchDocument } from "@opr-finance/feature-document";
import { watchFetchTransactions } from "@opr-finance/feature-transactions";
import { watchStartPageTrigger } from "./sagas/startPage";
import { watchFrontPageTrigger } from "./sagas/frontPage";
import { watchUserPageTrigger } from "./sagas/userPage";
import { watchLoginPageTrigger } from "./sagas/loginPage";
import { watchChangePageTrigger } from "./sagas/changePage";
import { watchLoanPageTrigger } from "./sagas/loanPage";
import { watchWithdrawTrigger } from "@opr-finance/feature-withdraw";
import { watchErrorTrigger } from "@opr-finance/feature-error";

export function* rootSaga() {
    yield all([
        fork(watchStartPageTrigger),
        fork(watchFrontPageTrigger),
        fork(watchUserPageTrigger),
        fork(watchLoginPageTrigger),
        fork(watchChangePageTrigger),
        fork(watchLoanPageTrigger),
        fork(watchFetchCustomerTrigger),
        fork(watchFetchAccountTrigger),
        fork(watchLogout),
        fork(watchCheckLoginStatus),
        fork(watchCompleteLogin),
        fork(watchStartLogin),
        fork(watchFetchNewsTrigger),
        fork(watchFetchStatements),
        fork(watchFetchDocument),
        fork(watchFetchTransactions),
        fork(watchInitializers),
        fork(watchWithdrawTrigger),
        fork(watchFetchTranslationTrigger),
        fork(watchErrorTrigger),
        fork(watchAccountSelection),
    ]);
}
